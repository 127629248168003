import axios from 'axios';
import React, { useState, useEffect } from 'react';
import * as S from './ModalStyled.js';

function sendUserMessage(message) {
  axios
    .post('/api/data', { message })
    .then(response => {
      console.log(response.data);
      // 받아온 데이터를 처리하는 로직
      processResponse(response.data);
    })
    .catch(error => {
      console.error(error);
      // 에러 처리 로직을 작성합니다.
    });
}

function processResponse(responseData) {
  //processResponse 함수는 서버에서 받아온 데이터를 가공하고,
  //채팅창에 답변을 출력하는 등의 로직을 처리
  const responseText = responseData.response.text;
  const intent = responseData.response.intent;
  const userId = responseData.context.userId;
  const timestamp = responseData.context.timestamp;

  console.log(`사용자 ID: ${userId}`);
  console.log(`시간: ${timestamp}`);
  console.log(`인텐트: ${intent}`);
  console.log(`답변: ${responseText}`);
  // 받아온 데이터를 활용하여 채팅창에 답변을 출력하는 등의 로직
  const chatList = document.getElementById('chatList');
  const newChatItem = document.createElement('li');
  newChatItem.textContent = responseText;
  chatList.appendChild(newChatItem);

  // 채팅창 스크롤을 아래로 이동합니다.
  chatList.scrollTop = chatList.scrollHeight;
}

const Modal = ({ setIsOpenModal }) => {
  const [userInput, setUserInput] = useState('');
  const [commentList, setCommentList] = useState([]);

  const handleInput = e => {
    setUserInput(e.target.value);
  };

  const handleSubmit = e => {
    sendUserMessage(userInput);
    setUserInput('');
  };

  const postButton = () => {
    fetch()
      .then(res => res.json())
      .then(data => console.log(data));

    handleSubmit();
    setCommentList(prev => [...prev, userInput]);
  };

  return (
    <S.ModalWrap>
      <S.ModalDetail>
        <S.ModalCloseBtn
          src="/images/Oh/modalclosebtn.png"
          onClick={() => {
            setIsOpenModal(prev => !prev);
          }}
        />
        <S.ChatWrap>
          <S.ChatList id="chatList" />
          {commentList.map((item, index) => (
            <S.ViewReviewWrap key={index}>
              <S.ViewReviewMent>{item}</S.ViewReviewMent>
            </S.ViewReviewWrap>
          ))}

          <S.WriteWrap>
            <S.WriteInput value={userInput} onChange={handleInput} />
            <S.SubmitBtn onClick={postButton}>전송</S.SubmitBtn>
          </S.WriteWrap>
        </S.ChatWrap>
      </S.ModalDetail>
    </S.ModalWrap>
  );
};

export default Modal;

// import React, { useState, useEffect } from 'react';
// import * as S from './ModalStyled.js';

// const Modal = ({ setIsOpenModal }) => {
//   return (
//     <S.ModalWrap>
//       <S.ModalDetail>
//         <S.ModalCloseBtn
//           src="/images/Oh/modalclosebtn.png"
//           onClick={() => {
//             setIsOpenModal(prev => !prev);
//           }}
//         />
//         <S.ChatWrap>
//           <S.WriteWrap>
//             <S.WriteInput />
//             <S.SubmitBtn>전송</S.SubmitBtn>
//           </S.WriteWrap>
//         </S.ChatWrap>
//       </S.ModalDetail>
//     </S.ModalWrap>
//   );
// };

// export default Modal;
