import styled from 'styled-components';

export const Back = styled.div`
  background-image: url('images/Oh/Shopexplainbackground3.png');
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Width = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const ExplainWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 70px;
  width: 90%;
`;

export const ExplainTitle = styled.div`
  margin-bottom: 4%;
  font-size: 30px;
  text-align: center;
  color: #565656;
  font-family: fontCafe;
  padding: 7% 0% 0% 0%;
  @media only screen and (max-width: 768px) {
    font-size: 4vw;
    padding: 3% 0%;
  }
  @media only screen and (max-width: 428px) {
    padding-bottom: 1%;
  }
`;

export const ExplainContent = styled.div`
  font-size: 50px;
  font-family: fontCafe;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 6.5vw;
  }
`;

export const ExplainImg = styled.div`
  width: 85%;
  margin: 0 auto;
  font-family: fontBase;
  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ImgAnswer = styled.div`
  padding: 40px 0px;
  font-size: var(--font-size-content1);
  font-family: fontCafe;
  text-align: center;
  line-height: 30px;
  @media only screen and (max-width: 768px) {
    font-size: var(--font-size-content2);
    line-height: 25px;
    padding: 8% 0;
  }
  @media only screen and (max-width: 428px) {
    font-size: var(--font-size-content3);
    line-height: 20px;
    padding: 8% 0;
  }
`;

export const ExplainDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-around;
  align-content: center;
  gap: 10px 2px;
  text-align: center;
  margin: 20px 0;
  @media only screen and (max-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    justify-items: center;
  }
  @media only screen and (max-width: 390px) {
    display: grid;
    text-align: center;
  }
`;
export const NoExplainContainer = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 16px;
  text-align: center;
  margin: 20px 0;
`;
export const NoExplain = styled.p`
  font-family: fontCafe;
  text-align: center;
`;
export const DetailBox = styled.div`
  width: 85%;
  padding: 30px 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 1px lightgray;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    width: 85%;
  }
`;

export const DetailSub = styled.div`
  padding: 8% 0 10% 0;
  color: #9e9e9e;
  font-size: 12px;
  @media only screen and (max-width: 820px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 390px) {
    padding: 5% 0 4% 0;
    font-size: 3vw;
  }
`;

export const DetailMain = styled.div`
  width: 100%;
  height: 45%;
  margin: 0 auto;
  color: darkblue;
  font-size: 22px;
  font-family: fontCafe;
  line-height: 30px;
  @media only screen and (max-width: 820px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media only screen and (max-width: 390px) {
    line-height: 22px;
  }
`;

export const DetailDate = styled.div`
  margin: 8px 0;
  color: #333333;
  font-size: 16px;
  font-family: fontCafe;
  font-weight: 300;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const DetailPlace = styled.div`
  color: #333333;
  font-size: 16px;
  font-family: fontCafe;
  line-height: 22px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;
