import React from 'react';
import * as S from './MainMunStyled.js';
import { Width } from '../../../styles/common.js';
import Fade from 'react-reveal/Fade';

const MainMun = ({ franchisee }) => {
  return (
    <div>
      <Width>
        <Fade bottom>
          <S.MainTitle ref={franchisee}>
            <S.Category>가맹안내</S.Category>
            <S.BoldTitle>새다론 공부방 창업 지원</S.BoldTitle>
            <S.InfoText>
              공부방 창업이 처음이시라고요?
              <br />
              새다론과 함께라면 공부방 창업은 누구나 할 수 있습니다! 오픈부터
              홍보, 운영까지 철저한 본사의 지원을 통해 어려움없이 든든하게
              시작하세요.
            </S.InfoText>
          </S.MainTitle>
        </Fade>
        <S.FranchiseWrap>
          <S.FranchiseImg
            src="images/Mun/Franchisee/Franchise.png"
            alt="창업지원 이미지"
          />
        </S.FranchiseWrap>
        <S.FranchiseWrapMedia>
          <S.FranchiseImgMedia
            src="images/Mun/Franchisee/FranchiseMedia.png"
            alt="창업지원 이미지"
          />
        </S.FranchiseWrapMedia>
      </Width>
      <S.Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#e0f4fc"
          fillOpacity="1"
          d="M0,64L60,90.7C120,117,240,171,360,186.7C480,203,600,181,720,154.7C840,128,960,96,1080,96C1200,96,1320,128,1380,144L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        />
      </S.Svg>
      <S.ReviewWrap>
        <Width>
          <Fade right>
            <S.Flex>
              <S.Category1>새다론 공부방</S.Category1>
              <S.BoldTitle>창업 후기</S.BoldTitle>
              <S.ProcessImg1
                src="images/Mun/Review.png"
                alt="창업후기 이미지"
              />
              <S.ProcessImg1Media
                src="images/Mun/ReviewMedia.png"
                alt="창업후기 이미지"
              />
            </S.Flex>
          </Fade>
        </Width>
      </S.ReviewWrap>
      <S.ZeroBackground>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ddf3fe"
            fillOpacity="1"
            d="M0,192L60,202.7C120,213,240,235,360,245.3C480,256,600,256,720,234.7C840,213,960,171,1080,154.7C1200,139,1320,149,1380,154.7L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          />
        </svg>
        {/* <Fade> */}
        <S.BoldTitleSpan padding>
          본사와 지사의 <S.BoldTitleSpan blue>든든한 지원</S.BoldTitleSpan>
        </S.BoldTitleSpan>
        <S.CategoryBlue>창업의 시작부터 안정화까지 밀착 지원</S.CategoryBlue>
        {/* </Fade> */}
        <S.ProcessImg2 small src="images/Mun/zero.png" alt="지원혜택 이미지" />
        <S.ZeroDiv>학생 모집부터 초도물품,</S.ZeroDiv>
        <S.ZeroDiv>홍보 용품 지원까지 쏟아지는 혜택</S.ZeroDiv>
      </S.ZeroBackground>
      <S.Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ddf3fe"
          fillOpacity="1"
          d="M0,160L40,144C80,128,160,96,240,80C320,64,400,64,480,74.7C560,85,640,107,720,117.3C800,128,880,128,960,128C1040,128,1120,128,1200,122.7C1280,117,1360,107,1400,101.3L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        />
      </S.Svg>
      <S.ProcessWrap>
        <Width>
          <Fade bottom>
            <S.TextCenter>
              <S.ProcessSubtitle>새다론 공부방</S.ProcessSubtitle>
              <S.BoldTitle>가맹 계약 절차 안내</S.BoldTitle>
            </S.TextCenter>
          </Fade>
          <Fade left>
            <S.ContractFlexGrid>
              {CONTRACT_PROCESS.map(({ id, number, title, text, image }) => (
                <S.Border key={id}>
                  <S.ProcessTitle>
                    <S.ProcessNumber bold blue>
                      {number}&nbsp;
                    </S.ProcessNumber>
                    <S.ContanctTitle>{title}</S.ContanctTitle>
                  </S.ProcessTitle>
                  <S.ProcessImg3 src={image} alt="계약절차 사진" />
                  <S.ProcessContent>
                    <S.ProcessText>{text}</S.ProcessText>
                  </S.ProcessContent>
                </S.Border>
              ))}
              {CONTRACT_PROCESS_LIST.map(({ id, number, title, image }) => (
                <S.Border key={id}>
                  <S.ProcessTitle>
                    <S.ProcessNumber bold blue>
                      {number}
                      <S.ContanctTitle>{title}</S.ContanctTitle>
                    </S.ProcessNumber>
                  </S.ProcessTitle>
                  <S.ProcessImg4 src={image} alt="계약절차 사진" />
                </S.Border>
              ))}
            </S.ContractFlexGrid>
          </Fade>
        </Width>
      </S.ProcessWrap>
      <S.Bluesvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill=" #22beff"
          fillOpacity="1"
          d="M0,256L48,261.3C96,267,192,277,288,250.7C384,224,480,160,576,149.3C672,139,768,181,864,170.7C960,160,1056,96,1152,85.3C1248,75,1344,117,1392,138.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        />
      </S.Bluesvg>
      <S.RecruitImg src="images/Mun/IdealPerson.png" alt="전문가 모집" />
      <S.RecruitImgMedia src="images/Mun/IdealMedia.png" alt="전문가 모집" />
    </div>
  );
};

export default MainMun;

const CONTRACT_PROCESS = [
  {
    id: 51,
    number: '01.',
    title: '가맹 상담 문의',
    text: '전반적인 공부방 운영방식과 가입 개설지역 등을 상담합니다.',
    image: 'images/Mun/processImage/1.png',
  },
  {
    id: 52,
    number: '02.',
    title: '맞춤 상담',
    text: '전국 각 지역의 지사와 본사는 출장상담 및 방문상담을 진행합니다.',
    image: 'images/Mun/processImage/2.png',
  },
  {
    id: 53,
    number: '03.',
    title: '가맹 계약서 작성',
    text: '프로그램 사용 계약서를 작성합니다.',
    image: 'images/Mun/processImage/3.png',
  },

  {
    id: 54,
    number: '04.',
    title: '가맹비 입금 및 초도물품 지급',
    text: '가맹비를 계좌로 입금한 후 초도물품을 지급받습니다.',
    image: 'images/Mun/processImage/4.png',
  },

  {
    id: 55,
    number: '05.',
    title: '본사 및 지사 교육',
    text: '본사 및 지사의 운영 교육 및 프로그램 사용 교육이 시작됩니다.',
    image: 'images/Mun/processImage/5.png',
  },

  {
    id: 56,
    number: '06.',
    title: '학부모 설명회 개최',
    text: '다양한 홍보지원을 통한 학부모 설명회를 진행하여 학생을 모집합니다.',
    image: 'images/Mun/processImage/6.png',
  },
];
const CONTRACT_PROCESS_LIST = [
  {
    id: 57,
    number: '07.',
    title: '개원',
    image: 'images/Mun/processImage/7.png',
  },

  {
    id: 58,
    number: '08.',
    title: '계속되는 회원 유치 및 성공!',
    image: 'images/Mun/processImage/8.png',
  },
];
