import styled from 'styled-components';

export const MainTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10%;
  @media ${({ theme }) => theme.device.tablet} {
    margin: auto 8%;
    width: 80%;
  }
`;

export const Category = styled.p`
  margin-bottom: 2%;
  font-size: 30px;
  color: #afafaf;
  font-family: fontCafe;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4vw;
  }
`;

export const ProcessSubtitle = styled(Category)`
  margin-bottom: 2%;
  font-size: 30px;
  color: #afafaf;
  font-family: fontCafe;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4vw;
    margin-bottom: 0%;
  }
`;

export const Category1 = styled.p`
  margin: -5% 0 2% 0;
  font-size: 30px;
  color: #afafaf;
  font-family: fontCafe;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4vw;
  }
`;

export const CategoryBlue = styled.p`
  color: #1abcff;
  margin-bottom: 5%;
  font-size: 28px;
  font-family: fontCafe;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4.5vw;
  }
`;

export const FranchiseeCategory = styled.p`
  margin: 10px 0 20px 10px;
  font-weight: 900;
  font-size: 33px;
  color: #ffffff;
  margin: ${({ edu }) => (edu ? '10px 0 20px 10px;' : '10px 0 20px 10px')};
  @media only screen and (max-width: 820px) {
    font-size: 27px;
    margin: 10px 0 10px 10px;
    margin: ${({ edu }) => (edu ? '10px 0 10px -10%;' : '10px 0 10px 10px')};
  }
`;

export const ZeroDiv = styled.div`
  font-family: fontCafe;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4.7vw;
  }
`;

export const BoldTitle = styled.p`
  margin-bottom: 25px;
  font-family: fontCafe;
  font-size: 50px;
  color: #404040;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 6.5vw;
  }
`;

export const InfoText = styled.div`
  width: 60%;
  margin-bottom: 8%;
  text-align: center;
  font-size: var(--font-size-content1);
  font-family: fontCafe;
  line-height: 30px;
  color: ${({ white }) => (white ? '#ffffff' : 'black')};

  @media ${({ theme }) => theme.device.tablet} {
    width: 95%;
    font-size: var(--font-size-content2);
  }

  @media only screen and (max-width: 428px) {
    font-size: var(--font-size-content3);
    line-height: 20px;
    width: 100%;
  }
`;

export const FranchiseWrap = styled.div`
  text-align: center;
  @media only screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const FranchiseWrapMedia = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    justify-content: center;
  }
`;
export const FranchiseImg = styled.img`
  width: 90%;
`;
export const FranchiseImgMedia = styled.img`
  width: 90%;
`;

export const FranchiseeInfoBoxWarp = styled.div`
  @media ${({ theme }) => theme.device.tablet} {
    row-gap: 7%;
    margin: auto 8%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    row-gap: 3%;
  }
`;

export const FranchiseeInfoBoxText = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 95%;
  padding: 3% 3% 5%;
  background-color: #09a6e8;
  word-break: break-all;
  border-radius: 25px;
  margin-bottom: 8%;
  @media only screen and (max-width: 820px) {
    padding: 3% 3% 3%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4.5vw;
  }
`;

export const TextWrap = styled.div`
  position: relative;
  width: 52%;
`;

export const InfoTextBox = styled.div`
  color: #ffffff;
  font-size: 18px;
  width: 100%;
  line-height: 29px;
  letter-spacing: 1px;
  margin-left: 30%;
  @media only screen and (max-width: 1024px) {
    font-size: 1.5vw;
    line-height: 20px;
  }
  @media only screen and (max-width: 820px) {
    font-size: 1.7vw;
  }
`;

export const InfoTextBoxPromote = styled(InfoTextBox)`
  margin-left: 15%;
  @media only screen and (max-width: 1024px) {
    margin-left: 10%;
    width: 85%;
    word-break: keep-all;
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const InfoTextBoxRight = styled(InfoTextBox)`
  margin-left: 13%;
  width: 93%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-left: 0%;
  }
  @media only screen and (max-width: 820px) {
    width: 110%;
    margin-left: -10%;
  }
`;

export const FranchiseeInfoBoxImge = styled.img`
  position: absolute;
  top: 22%;
  left: 55%;
  width: 45%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media only screen and (max-width: 1024px) {
    width: 35%;
    object-fit: contain;
    top: 28%;
    left: 58%;
  }
`;

export const FranchiseeInfoBoxTextRight = styled(FranchiseeInfoBoxText)`
  background-color: #8dc73f;
  margin-left: 5%;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4.5vw;
  }
`;

export const TextWrapRight = styled(TextWrap)`
  position: relative;
  width: 52%;
  margin-left: 47%;
`;

export const FranchiseeInfoBoxImge2 = styled(FranchiseeInfoBoxImge)`
  position: absolute;
  top: 41%;
  left: 0;
  width: 45%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media only screen and (max-width: 1024px) {
    width: 35%;
    top: 45%;
    left: 7%;
  }
`;

export const FranchiseeInfoBoxText3 = styled(FranchiseeInfoBoxText)`
  background-color: #fa68bd;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4.5vw;
  }
`;

export const FranchiseeInfoBoxImge3 = styled(FranchiseeInfoBoxImge)`
  position: absolute;
  top: 60%;
  left: 55%;
  width: 45%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media only screen and (max-width: 1024px) {
    width: 35%;
    top: 62%;
    left: 53%;
  }
`;

export const FranchiseeInfoBoxText4 = styled(FranchiseeInfoBoxText)`
  background-color: #ff9600;
  margin-left: 5%;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4.5vw;
  }
`;
export const FranchiseeInfoBoxImge4 = styled(FranchiseeInfoBoxImge)`
  position: absolute;
  top: 79%;
  left: 0;
  width: 45%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media only screen and (max-width: 1024px) {
    width: 35%;
    top: 81%;
    left: 7%;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ddf3fe;
  padding: 5% 10% 10% 10%;
  margin-top: -3%;
  margin-bottom: -9%;
`;

export const BoldTitleSpan = styled.span`
  margin-bottom: 10px;
  font-size: 60px;
  font-family: fontCafe;
  padding-top: 8%;
  line-height: 70px;
  max-width: 60%;
  text-align: center;
  color: ${({ blue }) => (blue ? '#1abcff' : '')};
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 9vw;
    line-height: 45px;
  }
`;

export const TextCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  line-height: 50px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 25px;
    line-height: 150%;
    text-align: center;
    font-size: 5.5vw;
  }
`;

export const ZeroBackground = styled(TextCenter)`
  background-image: url('images/Mun/ZeroBackground.png');
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ContractFlexGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5% 5%;
  margin: 7% 0 0 0%;
  padding-bottom: 30%;
  @media only screen and (max-width: 1024px) {
    width: 90%;
    margin: 5% auto;
  }
  @media ${({ theme }) => theme.device.tablet} {
    margin: 8% auto;
    grid-template-columns: 1fr 1fr;
    gap: 2% 5%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    row-gap: 1%;
    width: 80%;
  }
`;

export const ProcessWrap = styled.div`
  display: block;
  padding: 1% 0 10% 0;
  background-image: url('images/Mun/TeacherWave.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -8%;
  margin-bottom: -5%;
  @media only screen and (min-width: 1620px) {
    padding: 1% 0 0% 0;
    background-image: none;
    background-color: #ddf4fe;
  }
  @media only screen and (max-width: 1200px) {
    background-image: url('images/Mun/TeacherMedia.png');
    padding: 8% 0 30% 0;
  }
  @media only screen and (max-width: 1024px) {
    padding: 3% 0 15% 0;
  }
  @media only screen and (max-width: 768px) {
    background-image: none;
    background-color: #ddf4fe;
  }
  @media only screen and (max-width: 428px) {
    padding: 0 0 30% 0;
  }
`;

export const Border = styled.div`
  display: inline-block;
  height: 100%;
`;

export const ProcessTitle = styled.div`
  font-family: fontCafe;
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  padding: 20px;
  background-color: white;
  font-weight: 300;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  @media only screen and (max-width: 768px) {
    padding: 16px;
    height: 100px;
  }
  @media only screen and (max-width: 428px) {
    display: flex;
    height: 80px;
    padding: 12px;
  }
`;

export const ProcessContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  background-color: white;
  line-height: 40px;
  padding: 6%;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  @media only screen and (max-width: 830px) {
    height: 100px;
  }
  @media only screen and (max-width: 428px) {
    height: 80px;
    padding: 4% 6% 6% 8%;
  }
  @media only screen and (max-width: 375px) {
    height: 80px;
    padding: 6% 6% 6% 8%;
  }
`;

export const ProcessImg1 = styled.img`
  display: block;
  width: 100%;
  margin-top: 2%;
  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const ProcessImg1Media = styled.img`
  display: none;
  @media only screen and (max-width: 428px) {
    display: block;
    width: 110%;
    margin-top: 3%;
  }
`;

export const ProcessImg2 = styled.img`
  display: block;
  width: 60%;
  max-width: 852px;
  margin-bottom: 3%;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 5% 0;
  }
`;

export const ProcessImg3 = styled.img`
  display: block;
  width: 100%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
  @media ${({ theme }) => theme.device.mobile} {
  }
`;

export const ProcessImg4 = styled(ProcessImg3)`
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
`;

// export const ProcessImg = styled.img`
//   display: block;
//   width: 100%;
//   width: ${({ small }) => (small ? '40%' : '100%')};
//   margin-top: -1%;
// `;

export const Svg = styled.svg`
  display: block;
`;

export const ProcessNumber = styled.p`
  font-size: 25px;
  line-height: 28px;
  font-weight: 300;
  font-family: fontCafe;
  color: ${({ blue }) => (blue ? '#2355E9' : '')};

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 5vw;
  }
`;

export const ContanctTitle = styled(ProcessNumber)`
  font-size: 21px;
  color: #000000;
  @media only screen and (max-width: 1024px) {
    font-size: 2.2vw;
  }
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 20px;
  }
  @media only screen and (max-width: 428px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 375px) {
    font-size: 5vw;
  }
`;

export const ProcessText = styled(ProcessNumber)`
  font-size: 18px;
  font-family: fontCafe;
  word-break: keep-all;
  @media only screen and (max-width: 1024px) {
    font-size: 1.8vw;
  }
  @media only screen and (max-width: 830px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 24px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media only screen and (max-width: 428px) {
    width: 93%;
    font-size: 16px;
    word-break: break-all;
  }
  @media only screen and (max-width: 375px) {
    font-size: 4vw;
    line-height: 20px;
  }
`;

export const Bluesvg = styled.svg`
  display: none;
  @media only screen and (min-width: 1620px) {
    display: block;
    background-color: #ddf4fe;
  }
`;

export const RecruitImg = styled.img`
  display: block;
  width: 100%;
  margin-top: -10%;
  @media only screen and (min-width: 1620px) {
    margin-top: -1%;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const RecruitImgMedia = styled.img`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    margin-top: -20%;
  }
  @media only screen and (max-width: 428px) {
    margin-top: -1%;
  }
`;
