import styled, { css } from 'styled-components';

export const Animation = css`
  position: relative;
  animation: fadeInLeft 1s;
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
`;

export const Shake = css`
  @keyframes motion {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateY(20px);
    }
  }
  @keyframes motionTablet {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateY(15px);
    }
  }
  @keyframes motionMobile {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateY(5px);
    }
  }
  animation: motion 0.9s linear 0s infinite alternate;
  @media only screen and (max-width: 820px) {
    animation: motionTablet 1s linear 0s infinite alternate;
  }
  @media only screen and (max-width: 428px) {
    animation: motionMobile 1s linear 0s infinite alternate;
  }
`;

export const IntroduceBrand = styled.div``;

export const IntroImage = styled.div`
  background-image: url('images/Kim/IntroBackground.png');
  background-repeat: round;
  background-size: cover;
  background-color: #e8f9ff;
  @media only screen and (max-width: 770px) {
    background-image: url('images/Kim/IntroBackgroundT.png');
  }
  @media only screen and (max-width: 480px) {
    background-image: url('images/Kim/IntroBackgroundM.png');
  }
`;

export const IntroWrap = styled.div`
  display: flex;
  align-items: center;
  /* height: 730px; */
  @media only screen and (min-width: 1280px) {
    height: 925px;
    padding: 2%;
  }
  @media only screen and (max-width: 1279px) {
    height: 730px;
    padding: 2%;
  }
  @media only screen and (max-width: 768px) {
    display: block;
    height: 900px;
    padding-top: 25%;
  }
  @media only screen and (max-width: 630px) {
    display: block;
    height: 720px;
    padding-top: 25%;
  }
  @media only screen and (max-width: 520px) {
    height: 630px;
  }
  @media only screen and (max-width: 428px) {
    height: 505px;
    padding-top: 30%;
  }
  @media only screen and (max-width: 370px) {
    height: 430px;
  }
  @media only screen and (max-width: 310px) {
    height: 360px;
  }
`;

export const IntroTitleBox = styled.div`
  width: 70%;
  ${Animation}
  @media only screen and (min-width: 1280px) {
    width: 50%;
  }
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const IntroTitleImg = styled.img`
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    text-align: center;
  }
  @media only screen and (max-width: 480px) {
    width: 250px;
  }
`;

export const IntroTitleWrap = styled.div`
  background-color: #f1e278;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  height: 33px;
  line-height: 35px;
  padding: 5px;

  @media only screen and (max-width: 600px) {
    height: 27px;
    line-height: 29px;
  }
  @media only screen and (max-width: 500px) {
    height: 20px;
    line-height: 22px;
  }
`;

export const IntroTitle = styled.span`
  font-family: fontBold;
  font-size: 29px;
  color: #053968;
  @media only screen and (max-width: 600px) {
    font-size: 4.5vw;
  }
`;

export const MainSpace = styled.img`
  position: absolute;
  left: 105%;
  top: -20%;
  display: block;
  ${Shake}
  @media only screen and (max-width: 1400px) {
    left: 75%;
    top: -25%;
  }
  @media only screen and (max-width: 1280px) {
    left: 70%;
    top: -15%;
  }
  @media only screen and (max-width: 890px) {
    left: 50%;
    top: -35%;
  }
  @media only screen and (max-width: 768px) {
    left: 70%;
    top: -10%;
    width: 140px;
  }
  @media only screen and (max-width: 428px) {
    width: 100px;
    top: -10%;
  }
`;

export const MainSpace2 = styled.img`
  position: absolute;
  display: block;
  left: -135%;
  bottom: -20%;
  ${Shake}
  @media only screen and (max-width: 1400px) {
    left: -90%;
    bottom: -25%;
  }
  @media only screen and (max-width: 850px) {
    width: 140px;
  }
  @media only screen and (max-width: 770px) {
    left: 70%;
    bottom: -25%;
    width: 120px;
  }
  @media only screen and (max-width: 428px) {
    left: 65%;
    bottom: -20%;
    width: 100px;
  }
`;

export const IntroContent = styled.span`
  font-family: fontBold;
  font-size: 29px;
  color: #ffffff;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
  @media only screen and (max-width: 600px) {
    font-size: 4.5vw;
  }
`;

export const IntroSubtitleWrap = styled.span`
  display: inline-block;
  width: 430px;
  font-size: 50px;
  margin-top: 15px;
  color: #ffffff;
  font-family: fontCafe;
  @media only screen and (min-width: 1280px) {
    width: 500px;
    font-size: 55px;
  }
  @media only screen and (max-width: 600px) {
    width: 370px;
    font-size: 45px;
  }
  @media only screen and (max-width: 500px) {
    width: 330px;
    font-size: 8vw;
  }
  @media only screen and (max-width: 412px) {
    width: 270px;
  }
  @media only screen and (max-width: 337px) {
    width: 222px;
  }
`;

export const IntroSubtitleYellow = styled.span`
  color: #fcf184;
`;

export const IntroSubtitleBlue = styled.span`
  color: #92dafe;
`;

export const IntroImgWrap = styled.div`
  position: relative;
  @media only screen and (max-width: 768px) {
    text-align: center;
    padding: 0 10%;
  }
`;

export const IntroImg = styled.img`
  width: 100%;
  ${Shake}
  @media only screen and (max-width: 768x) {
    width: 90%;
  }
`;

export const PlusImage = styled.div`
  background-image: url('images/Kim/Maintop2.png');
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MixContents = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10% 0 1% 0;
  width: 60%;
  margin: 0 auto;
  @media only screen and (max-width: 960px) {
    margin: 0 auto;
    width: 90%;
    gap: 2%;
  }
  @media only screen and (max-width: 428px) {
    padding: 15% 0 5% 0;
  }
`;

export const IntroLogoWrap = styled.div``;

export const MixLogo = styled.img`
  width: 60%;
  display: flex;
  margin: 0 auto;
  padding-bottom: 5%;
`;

export const MixContent = styled.img`
  display: flex;
  margin: 0 auto;
  width: 80%;

  @media only screen and (max-width: 975px) {
    font-size: 4.2vw;
  }
  @media only screen and (max-width: 428px) {
    margin: 2% auto;
  }
`;

export const DescriptionWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 4% 0 8% 0;
`;

export const Description = styled.div`
  font-family: 'fontCafe';
  font-size: var(--font-size-content0);
  color: #333333;
  text-align: center;
  line-height: 2;
  width: 80%;
  @media only screen and (max-width: 1055px) {
    font-size: var(--font-size-content1);
    line-height: 2;
  }
  @media only screen and (max-width: 600px) {
    font-size: var(--font-size-content2);
    width: 90%;
  }
`;

export const DescriptionBold = styled.span`
  font-family: fontCafe;
  color: darkblue;
`;

export const Br = styled.div`
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const span = styled.span`
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const BlueSvg = styled.svg`
  display: block;
  background-color: #e8f9ff;
`;

export const BrandImage = styled.div`
  background-image: url('images/Kim/MainBlue.png');
  background-repeat: round;
  background-size: cover;
  background-color: #f7f3e6;
  @media only screen and (max-width: 1055px) {
    background-image: url('images/Kim/BIImage.png');
    background-repeat: no-repeat;
  }
`;

export const IntroduceBIWrap = styled.div`
  padding: 16% 0 10% 0;
  @media only screen and (min-width: 1920px) {
    padding: 16% 0 13% 0;
  }
  @media only screen and (max-width: 1280px) {
    padding: 5 0 10% 0;
  }
  @media only screen and (max-width: 1055px) {
    padding: 10% 0 15% 0;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 6% 0 17% 0;
  }
  @media only screen and (max-width: 428px) {
    padding: 4% 0 18% 0;
  }
`;

export const ExplainBrand = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 2% auto;
  gap: 5%;

  @media only screen and (max-width: 1280px) {
    margin-top: 10px;
  }
  @media only screen and (max-width: 1055px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 5% 0 10% 0;
    margin-top: 40px;
  }
  @media only screen and (max-width: 428px) {
    padding: 1% 0 10% 0;
  }
`;

export const BILogoWrap = styled.div`
  width: 400px;
  @media only screen and (max-width: 1055px) {
    grid-template-columns: repeat(1, 1fr);
    width: 45%;
    margin: 0 auto;
  }
`;

export const BILogo = styled.img`
  width: 100%;
`;

export const LogoContent = styled.div`
  width: 100%;
  padding-top: 6%;
  @media only screen and (max-width: 1055px) {
    text-align: center;
    padding-top: 3%;
  }
  @media only screen and (max-width: 428px) {
    padding-top: 0%;
  }
`;

export const BITitleWrap = styled.div`
  display: flex;
  @media only screen and (max-width: 1280px) {
    margin-top: 3%;
  }
  @media only screen and (max-width: 1055px) {
    display: block;
  }
  @media only screen and (max-width: 428px) {
    margin-top: 0%;
  }
`;

export const BITitle = styled.img`
  width: 60%;
  @media only screen and (max-width: 1055px) {
    font-size: 50px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 6.8vw;
  }
`;

export const BISubTitle = styled.div`
  font-family: fontCafe;
  font-size: 25px;
  padding: 3% 0 0 2%;
  color: #ffffff;
  @media only screen and (max-width: 1055px) {
    padding-top: 1%;
    font-size: 30px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 8px 0;
    font-size: 20px;
  }
`;

export const ExplainBI = styled.div`
  font-family: 'fontCafe';
  font-size: var(--font-size-content1);
  line-height: 2;
  margin: 30px 0;
  width: 100%;
  @media only screen and (max-width: 1055px) {
    margin: 20px auto;
    width: 80%;
  }
  @media only screen and (max-width: 600px) {
    font-size: var(--font-size-content2);
    width: 90%;
  }
`;
export const WhiteColor = styled.span`
  color: #ffffff;
  font-family: fontCafe;
`;
export const BlueColor = styled.span`
  color: #2355e9;
  font-family: fontCafe;
`;

export const YellowColor = styled.span`
  /* color: #f1f226; */
  color: #e7e838;
  font-family: fontCafe;
`;

export const PinkColor = styled.span`
  color: #e22565;
  font-family: fontCafe;
`;

export const ExplainBIExtra = styled.div`
  padding-top: 5%;
  font-size: 20px;
  line-height: 160%;
  @media only screen and (max-width: 1055px) {
    font-size: 23px;
    padding-top: 3%;
  }
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const DownloadWrap = styled.div`
  display: flex;
  margin-top: 4%;
  gap: 2%;
  @media only screen and (max-width: 1055px) {
    justify-content: center;
    margin-top: 2%;
  }
  @media only screen and (max-width: 925px) {
    justify-content: center;
    gap: 2%;
    font-size: 12px;
  }
`;

export const AIDownload = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  padding-left: 8px;
  border: 1px solid #edd900;
  border-radius: 20px;
  background-color: #edd900;
  font-family: FontCafe;
  font-size: 14px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    width: 100px;
    height: 25px;
    font-size: 13px;
  }
`;

export const PNGDownload = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  padding-left: 5px;
  border: 1px solid #edd900;
  border-radius: 20px;
  background-color: #edd900;
  font-family: FontCafe;
  font-size: 14px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    width: 100px;
    height: 25px;
    font-size: 13px;
  }
`;

export const DownloadImg = styled.img`
  width: 20px;
`;
