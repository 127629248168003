import styled from 'styled-components';

export const TextCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-family: fontbold;
  font-size: 30px;
  line-height: 50px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 1%;
    margin-top: 8%;
    line-height: 150%;
    text-align: center;
    font-size: 5.5vw;
  }
`;

export const Category = styled.p`
  margin-bottom: 2%;
  font-size: 30px;
  font-family: fontCafe;
  color: #afafaf;
  color: ${({ blue }) => (blue ? '#1abcff' : '#b5b5b5')};
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4vw;
    margin-bottom: 0%;
  }
`;

export const BoldTitle = styled.p`
  margin-bottom: 25px;
  font-family: fontbold;
  font-family: fontCafe;
  font-size: 50px;
  color: #404040;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 7vw;
    margin-bottom: 1%;
  }
`;

export const StudyRoomWrap = styled.div`
  width: 100%;
  padding-top: 70px;
  margin: 0 auto;
  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 1%;
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 4fr);
    display: grid;
  }
`;

export const Ul = styled.ul`
  position: relative;
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  z-index: 5;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 2vw;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 10px;
  }
  @media only screen and (max-width: 480px) {
    border-radius: 20px;
    padding: 4.3%;
    font-size: 3.5vw;
  }
`;

export const StudyRoomSearch = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 50%;
  height: 50px;
  padding: 3% 0%;
  margin: 0 auto;
  gap: 1%;
  @media only screen and (max-width: 780px) {
    width: 70%;
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

export const Span = styled.span`
  font-size: 18px;
  font-weight: ${({ bold }) => (bold ? 700 : '')};
  font-size: ${({ small }) => (small ? '12px' : '18px')};
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    font-size: ${({ small }) => (small ? '10px' : '14x')};
  }
`;

export const DropBorder = styled.div`
  position: absolute;
  top: 40px;
  left: 0%;
  width: 90%;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 4% 5%;
  margin-top: 10px;
  font-size: 12px;
  line-height: 25px;

  @media only screen and (max-width: 820px) {
    top: 40px;
    left: -1%;
    width: 91%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    top: 25px;
  }
  @media only screen and (max-width: 480px) {
    top: 90%;
  }
`;

export const ToggleWrap = styled.div`
  position: relative;
  cursor: pointer;
`;

export const Toggle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const DropList = styled.div`
  display: flex;
  gap: 3%;
  font-size: 16px;
  line-height: 30px;
  @media only screen and (max-width: 480px) {
    span {
      font-size: 12px;
      line-height: 25px;
    }
  }
`;

export const SearchWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  justify-items: end;
  @media only screen and (max-width: 840px) {
    margin-top: 10px;
    height: 100%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    margin: 2% 0 8% 0;
    @media only screen and (max-width: 310px) {
      display: flex;
    }
  }
`;

export const SearchText = styled.input`
  box-sizing: border-box;
  padding-left: 12px;
  width: 100%;
  border-radius: 5px;
  font-size: var(--font-size-content2);

  @media only screen and (max-width: 840px) {
    padding: 1.7% 4%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    height: 45px;
    font-size: var(--font-size-content3);
  }
`;

export const SearchBtn = styled.button`
  width: 80%;
  background-color: #006593;
  color: white;
  margin-left: 10px;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  @media only screen and (max-width: 840px) {
    font-size: var(--font-size-content2);
    margin-left: 20%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size: var(--font-size-content3);
    margin: 0;
    height: 45px;
  }
  @media only screen and (max-width: 310px) {
    width: 9vh;
  }
`;

export const SearchMobile = styled(StudyRoomSearch)`
  display: none;
  @media only screen and (max-width: 480px) {
    display: block;
    width: 90%;
    margin: 0 auto;
    gap: 1%;
  }
`;

export const SearchWrapMobile = styled(SearchWrap)`
  margin: 8% 0 0% 0;
  height: 45%;
  width: 100%;
  @media only screen and (max-width: 428px) {
    margin: 8% 0 2px 0;
  }
`;
export const UlMobile = styled(Ul)`
  position: relative;
`;

export const StudyRoomDetail = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 40px 0 50px 0;
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: grid;
    width: 80%;
    margin: 0 auto;
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    padding: 40px 0 30px 0;
  }
`;

export const RoomMapWrap = styled.div`
  width: 55%;

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const StudyRoomMap = styled.img`
  width: 100%;
`;

export const FranchiseList = styled.div`
  width: 43%;
  height: 610px;
  border: 1px solid #d4d4d4;

  @media only screen and (min-width: 1720px) {
    height: 710px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin: 10% auto;
  }
`;

export const TheNumWrap = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d4d4d4;
  @media only screen and (max-width: 480px) {
    padding: 5px 0px;
  }
`;

export const TheNumFranchise = styled.div`
  padding: 3%;
  font-family: fontBase;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 2%;
  }
`;

export const FranchiseListWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-height: 520px;
  overflow-x: hidden;
  border-radius: 10px;
  @media only screen and (min-width: 1720px) {
    max-height: 610px;
  }
  @media only screen and (max-width: 1280px) {
    max-height: 530px;
  }
`;

export const FranchiseEmptyList = styled.div`
  width: 43%;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin: 5% auto;
  }
`;

export const FranchiseEmptyContent = styled.div``;

export const FranchiseEmptyWrap = styled.div`
  display: flex;
  margin: 0 auto;
  height: 525px;
  width: 100%;
  /* overflow: auto; */
`;

export const CountCommentWrap = styled.div`
  display: flex;
  margin: 10% auto;
  height: 300px;
  line-height: 50px;
`;

export const CountComment = styled.span`
  display: block;
  font-size: 18px;
  font-family: ${({ bold }) => (bold ? 'fontbold' : '')};
  font-size: ${({ size }) => (size ? '20px' : '18px')};
`;
export const CountComments = styled.div`
  text-align: center;
`;

export const FranchiseBox = styled.div`
  padding: 10px;
  margin: 0 auto;
  height: fit-content;
  width: 90%;
  background-color: ${({ clicked }) => (clicked ? '#E9E9E9' : '')};
  border-radius: 2px;
  border-bottom: 1px solid lightgray;
`;

export const FranchiseMouse = styled.div``;

export const FranchiseImgWrap = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const FranchiseImg = styled.img`
  width: 100%;
  height: 80%;
  @media only screen and (max-width: 1280px) {
    height: 100px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    height: 120px;
  }
`;

export const StudyRoomtype = styled.div`
  position: absolute;
  border-radius: 50px;
  padding: 8px;
  margin: 5px 0 0 5px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-family: fontBase;
  font-weight: 900;
  color: #ffffff;
  @media only screen and (min-width: 1720px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

export const StudyRoomService = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  word-break: break-all;
  @media only screen and (min-width: 1720px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 450px) {
    max-width: 90%;
    padding: 4px;
  }
`;

export const FranchiseTitle = styled.div`
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  word-break: break-all;
  font-family: fontCafe;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
  }
`;

export const FranchiseIn = styled.div`
  font-size: 14px;
  word-break: keep-all;
  line-height: 20px;
  font-family: 'fontCafe';
  @media only screen and (min-width: 1720px) {
    font-size: 14px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 90%;
  }
`;

export const FranchiseeTelWrap = styled.div`
  padding: 5px 0px;
  display: flex;
  line-height: 16px;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 14px;
  }
`;

export const TelIcon = styled.img`
  width: 16px;
`;

export const FranchiseeTel = styled.div`
  font-size: 14px;
  font-family: 'fontCafe';
  vertical-align: 'bottom';
`;
