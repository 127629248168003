import React, { useEffect, useState } from 'react';
import * as S from './ShopExplainStyled.js';
import PagenationPlain from '../ShopFind/pagenationPlain.js';
import Fade from 'react-reveal/Fade';

const ShopExplain = ({ business }) => {
  const [explain, setExplain] = useState([]);
  const [exLoading, setExLoading] = useState(true);

  return (
    <S.Back>
      <S.Width>
        <S.ExplainWrap ref={business}>
          <Fade bottom>
            <S.ExplainTitle>사업설명회</S.ExplainTitle>
            <S.ExplainContent>새다론 전국 사업설명회 일정</S.ExplainContent>
          </Fade>
          <S.ExplainImg>
            <S.ImgAnswer>
              공부방 창업 성공을 위한 <br />
              모든 노하우와 전략을 공유합니다.
            </S.ImgAnswer>
          </S.ExplainImg>
          {explain.result && explain.result.length === 0 ? (
            <S.NoExplainContainer>
              <React.Fragment>
                <img src="images/OhExplain/Alert.png" />
                <S.NoExplain>등록된 일정이 없습니다.</S.NoExplain>
              </React.Fragment>
            </S.NoExplainContainer>
          ) : (
            ''
          )}
          <S.ExplainDetail>
            {explain.result &&
              explain.result.map(({ id, title, date, location }) => {
                return (
                  <S.DetailBox key={id}>
                    <S.DetailMain>{title}</S.DetailMain>
                    <S.DetailDate>
                      {new Intl.DateTimeFormat('default', {
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      }).format(new Date(date))}
                    </S.DetailDate>
                    <S.DetailPlace>{location}</S.DetailPlace>
                  </S.DetailBox>
                );
              })}
          </S.ExplainDetail>
          <PagenationPlain explain={explain} setExplain={setExplain} />
        </S.ExplainWrap>
      </S.Width>
    </S.Back>
  );
};

export default ShopExplain;
