import styled from 'styled-components';

export const bannerContainer = styled.div`
  text-align: center;
  margin: 30px 0 60px 0;
`;
export const bannerImg = styled.img`
  max-width: 900px;
  border-radius: 10px;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    width: 90%;
  }
`;
