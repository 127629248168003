import React from 'react';
import ShopExplain from './ShopExplain/ShopExplain';
import ShopFind from './ShopFind/ShopFind';
import ApplyBanner from './ApplyBanner';

const MainOh = ({ findFranchisee, business }) => {
  return (
    <>
      <ShopFind findFranchisee={findFranchisee} />
      <ShopExplain business={business} />
      <ApplyBanner />
    </>
  );
};

export default MainOh;
