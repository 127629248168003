import styled from 'styled-components';

export const MapWrap = styled.div`
  position: relative;
  width: 100%;
  height: 610px;
  @media only screen and (min-width: 1720px) {
    height: 710px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    height: 400px;
  }
`;

export const NaverMap = styled.div`
  position: relative;
`;

export const UserGps = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 10px;
  bottom: 365px;
  width: 30px;
  height: 30px;
  padding: 3px;
  border: 1px solid #000000;
  background-color: #ffffff;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    bottom: 155px;
  }
`;

export const Img = styled.img`
  position: absolute;
  right: 2px;
  top: 2px;
  width: 23px;
  height: 23px;
  @media only screen and (max-width: 480px) {
  }
`;
