import styled from 'styled-components';

export const TotalFooter = styled.footer`
  display: grid;
  background-color: #303030;
`;

export const FooterCenterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-items: center;
  margin: 3%;
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr;
    justify-items: start;
    margin: 6%;
  }
`;

export const FooterImgContainer = styled.div`
  width: 100%;
  flex-direction: column;
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10%;
  }
  @media only screen and (max-width: 428px) {
    align-items: center;
  }
`;

export const MainImg = styled.img`
  width: 60%;
  @media only screen and (max-width: 1200px) {
    width: 65%;
  }
`;

export const InquiryImg = styled.img`
  width: 100%;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const InquiryMedia = styled.img`
  display: none;
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    margin: 3% 0;
  }
  @media only screen and (max-width: 428px) {
    width: 60%;
    margin: 7% 0;
  }
`;

export const InfoWrap = styled.div`
  font-size: 14px;
  font-family: fontBase;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 13px;
  }
`;
export const CompanyInfoWrap = styled.article`
  display: flex;
  justify-content: center;
`;

export const Footersection = styled.div`
  line-height: 170%;
  color: #ffffff;
  font-weight: 900;
`;

export const CompanyInfoNum = styled.div`
  display: flex;
  @media only screen and (max-width: 860px) {
    display: block;
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
  }
`;

export const RegisterationNum = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 768px) {
  }
  @media ${({ theme }) => theme.device.mobile} {
  }
`;

export const TelFaxWrap = styled.div`
  display: flex;
`;

export const CompanyInfoContent = styled.div``;

export const Copyright = styled.div``;

export const InquiryNum = styled.div`
  width: 70%;
`;

export const FloatingBtnWrap = styled.div`
  position: fixed;
  bottom: 180px;
  right: -40px;
  width: 200px;
  @media only screen and (max-width: 1000px) {
    bottom: 160px;
    right: -80px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    bottom: 150px;
    right: -70px;
  }
  @media only screen and (max-width: 530px) {
    bottom: 120px;
    right: -90px;
  }
  @media only screen and (max-width: 428px) {
    bottom: 110px;
    right: -100px;
  }
`;

export const BookingBtn = styled.img`
  display: none;
  width: 60%;
  position: absolute;
  :hover {
    cursor: pointer;
  }
  animation: motion 0.9s linear 0s infinite alternate;
  @keyframes motion {
    0% {
      margin-top: 0px;
    }
    100% {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 1000px) {
    width: 50%;
  }
  @media only screen and (max-width: 820px) {
    animation-play-state: paused;
    display: none;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 50%;
  }
  @media only screen and (max-width: 428px) {
    width: 40%;
  }
`;
