/* eslint-disable */
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { MarkerClustering } from './MarkerClustering';
import { API } from '../../../../config';
import * as S from './NaverMapsStyled.js';

let map;
let markerClustering;
let markers = [];
const NaverMaps = ({  
  data,
  setData,
  cate,
  keyword,
  setKeyword,
  bounds,
  setBounds,
  userLocation,
  setUserLocation,
  currentPage,
  setCurrentPage,
  mapData,
  setMapData,
  clickedItem,
  setClickedItem
  }) => {
    const mapElement = useRef(null);
    var htmlMarker1 = {
        content:
          '<div style="cursor:pointer;width:34px;height:34px;line-height:34px;border-radius:50%;color:white;text-align:center;font-weight:bold;font-size:20px;background:#4dbdf0;border:1px solid rgb(255, 255, 255);box-shadow:#4dbdf0 0px 0px 6px 6px"></div>',
        size: N.Size(40, 40),
        size: N.Size(40, 40),
        anchor: N.Point(20, 20),
      },
      htmlMarker2 = {
        content:
          '<div style="cursor:pointer;width:34px;height:34px;line-height:34px;border-radius:50%;color:white;text-align:center;font-weight:bold;font-size:20px;background:#00a0e9;border:1px solid rgb(255, 255, 255);box-shadow:#00a0e9 0px 0px 6px 6px"></div>',
        size: N.Size(40, 40),
        anchor: N.Point(20, 20),
      },
      htmlMarker3 = {
        content:
          '<div style="cursor:pointer;width:34px;height:34px;line-height:34px;border-radius:50%;color:white;text-align:center;font-weight:bold;font-size:20px;background:#0070a3;border:1px solid rgb(255, 255, 255);box-shadow:#0070a3 0px 0px 6px 6px"></div>',
        size: N.Size(40, 40),
        anchor: N.Point(20, 20),
      };

    //페이지네이션 클릭시 list 불러오기
    function changePage() {
      fetch(`${API.list}?keyword=${keyword}&page=${currentPage}&cate=${cate}`)
        .then(res => res.json())
        .then(data => {
          setData(data);
        });
    }
    function onSuccessGeolocation(position){
      let [userLat, userLng] = [position.coords.latitude, position.coords.longitude];
      if(map == undefined){
        newMap(userLat, userLng);      
      }else{
        setClickedItem(undefined);
        map.setCenter(new naver.maps.LatLng(userLat, userLng));
        map.setZoom(13);
        setCurrentPage(1);
      }     
    }
    function onErrorGeolocation(){
      /** 서울시청 좌표 */
      let [cityLat, cityLng] = [37.5642, 126.9746];
      if(map == undefined){
        newMap(cityLat, cityLng);      
      }else{
        setClickedItem(undefined);
        map.setCenter(new naver.maps.LatLng(cityLat, cityLng));
        map.setZoom(13);
        setCurrentPage(1);
      }
    }
    function getGeolocation() {
      /** 현재 위치 정보 가져오기 */ 
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(onSuccessGeolocation, onErrorGeolocation);
      }else{
        onErrorGeolocation();
      }
    }
    function newMap(latitude, longitude) { //전달받은 위치를 중심으로 하는 지도 생성
      const mapOptions = {
        center: new naver.maps.LatLng(latitude, longitude),
        zoom: 13,
        zoomControl: true,
        zoomControlOptions: {
          position: naver.maps.Position.TOP_RIGHT,
        },
        minZoom: 7,
        maxZoom: 20
      };
      map = new naver.maps.Map(mapElement.current, mapOptions);
      fetch(`${API.marker}?keyword=${keyword}&page=${currentPage}&cate=${cate}`)
      .then(res => res.json())
      .then(data => {
        setMapData(data.result);
        for (var i = 0; i < data.result.length; i++) {
          let spot = data.result[i];
          let latlng = new naver.maps.LatLng(spot.lat, spot.lng);
          let marker = new naver.maps.Marker({
            position: latlng,
            map: map
          });
          markers.push(marker);
        }
        markerClustering = new MarkerClustering({
          minClusterSize: 2,
          maxZoom: 20,
          map: map,
          markers: markers,
          disableClickZoom: false,
          gridSize: 150,
          icons: [htmlMarker1, htmlMarker2, htmlMarker3],
          indexGenerator: [5, 15, 30],
          stylingFunction: function (clusterMarker, count) {
            $(clusterMarker.getElement()).find('div:first-child').text(count);
          },
        });
      });
    }
    useEffect(() => {
      const { naver } = window;
      if (!mapElement.current || !naver) return;
      
      //위치정보 요청후 지도 생성
      getGeolocation();
    }, []);

    useEffect(() => {
      changePage();
    }, [currentPage]);

    useEffect(() => {
      if(clickedItem != undefined && map != undefined){
        //map.setCenter(new naver.maps.LatLng(clickedItem.lat, clickedItem.lng));
        map.setCenter(new naver.maps.LatLng(mapData[clickedItem].lat, mapData[clickedItem].lng));
        map.setZoom(16);
      }
    }, [clickedItem]);

    useEffect(() => {
      /** 기존 마커 제거 */
      for (var ii = 0; ii < markers.length; ii++) {
        markers[ii].setMap(null);
      }
      markers = [];//초기화
      let firstData = mapData[0];
      if(firstData != undefined && map != undefined){
        map.setCenter(new naver.maps.LatLng(firstData.lat, firstData.lng));
        map.setZoom(13);
      }
      /** 마커 생성 */
      for (var i = 0; i < mapData.length; i++) {
        let spot = mapData[i];
        let latlng = new naver.maps.LatLng(spot.lat, spot.lng);
        let marker = new naver.maps.Marker({
          position: latlng,
          map: map
        });
        markers.push(marker);
      }
      if(markerClustering != undefined){
        markerClustering.setMarkers(markers);//클러스터링할 마커 다시 세팅
      }
    }, [mapData]);

    return (
      <S.NaverMap>
        {/* <S.MapWrap ref={mapElement} onClick={sendFetch} /> */}
        <S.MapWrap ref={mapElement} />
        <S.UserGps onClick={getGeolocation}>
          <S.Img src="images/Mun/gps.png" alt="gps" />
        </S.UserGps>
      </S.NaverMap>
    );
  };

export default NaverMaps;