import React, { useState } from 'react';
import * as S from './ApplyBannerStyled';
import MailPopup from './MailPopup';

const ApplyBanner = () => {
  const [open, setOpen] = useState(false);
  const clickBanner = () => {
    setOpen(true);
  };
  return (
    <S.bannerContainer>
      <S.bannerImg
        src="images/Oh/applyBanner.jpg"
        alt="창업상담신청 이미지"
        onClick={clickBanner}
      />
      <MailPopup open={open} setOpen={setOpen} />
    </S.bannerContainer>
  );
};

export default ApplyBanner;
