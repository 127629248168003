import React, { useState } from 'react';
import * as S from './FooterStyled.js';
import Modal from '../../pages/Main/MainOh/Modal/Modal.js';

const Footer = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <S.TotalFooter>
        <S.FooterCenterContainer>
          <S.MainImg
            src="/images/Nav/NavWhiteLogo.png"
            alt="새다론 공부방 로고"
          />
          <S.InquiryMedia
            src="/images/Oh/phonenumber.png"
            alt="창업 및 입회 문의 : 1588-7225"
          />
          <S.InfoWrap>
            <S.CompanyInfoWrap>
              <S.Footersection>
                <address>(주)다비수디지탈 &nbsp;|&nbsp; 대표 : 최갑숙</address>
                <S.CompanyInfoNum>
                  <S.RegisterationNum>
                    사업자등록번호 : 237-87-00791 | 통신판매번호 :
                    2018-서울서초-0945호
                  </S.RegisterationNum>
                </S.CompanyInfoNum>
                서울특별시 서초구 서초중앙로63 리더스빌딩 1007호
                <div>Tel : 02-588-6868 | Fax : 02-588-6867</div>
                <S.CompanyInfoContent>
                  개인정보관리책임자 : 이정금
                </S.CompanyInfoContent>
                <S.Copyright>&copy;2023 Dabisu.All rights reserved</S.Copyright>
              </S.Footersection>
            </S.CompanyInfoWrap>
          </S.InfoWrap>
          <S.InquiryNum>
            <S.InquiryImg
              src="/images/Oh/phonenumber.png"
              alt="창업 및 입회 문의 : 1588-7225"
            />
          </S.InquiryNum>
        </S.FooterCenterContainer>
        <S.FloatingBtnWrap>
          <S.BookingBtn
            src="/images/Oh/ModalImg.png"
            alt="새다론 챗봇"
            // onClick={() => {
            //   setIsOpenModal(true);
            // }}
            onClick={() => alert('준비중입니다.')}
          />
        </S.FloatingBtnWrap>
      </S.TotalFooter>
      {isOpenModal && <Modal setIsOpenModal={setIsOpenModal} />}
    </>
  );
};

export default Footer;
