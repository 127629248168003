import styled, { css } from 'styled-components';

export const Program = styled.div``;

export const ProgramImage = styled.div`
  background-image: url('images/Kim/MainBeige.png');
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LearningProgram = styled.div`
  padding-top: 12%;
  background-color: #f7f3e6;
  @media only screen and (max-width: 428px) {
    padding-top: 20%;
  }
`;

export const ProgramTitle = styled.div`
  font-size: var(--font-size-title1);
  display: flex;
  justify-content: center;
  font-family: fontCafe;
  color: #404040;
  margin-top: -50px;

  @media only screen and (max-width: 768px) {
    font-size: var(--font-size-title2);
  }
`;

export const ProgramSubTitle = styled.div`
  font-size: var(--font-size-content0);
  display: flex;
  justify-content: center;
  padding: 2%;
  font-family: fontCafe;
  color: #afafaf;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: var(--font-size-content1);
  }
`;

export const ProgramContent = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid #ffffff;
  border-radius: 14px;
  background-color: #ffffff;
  @media only screen and (max-width: 905px) {
    width: 83%;
  }
`;

export const ProgramWrap = styled.div`
  width: 100%;
`;

export const ExplainWrap = styled.div`
  margin: 5% 0% 5% 5%;
  @media only screen and (max-width: 905px) {
    margin: 7%;
  }
`;

export const ProgramName = styled.img`
  margin-bottom: 3%;
  font-family: fontBold;
  width: 30%;
  @media only screen and (max-width: 905px) {
    font-size: 3.5vw;
    width: 35%;
  }
`;

export const ProgramExplain = styled.div`
  font-size: var(--font-size-content1);
  line-height: 1.8;
  text-align: left;
  font-family: 'fontCafe';
  @media only screen and (max-width: 905px) {
    font-size: var(--font-size-content2);
  }
`;

export const ExplainBold = styled.div`
  margin-bottom: 2px;
  font-family: fontBold;
  font-weight: lighter;
`;

export const ProgramImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  width: 40%;
  @media only screen and (max-width: 905px) {
    display: none;
  }
`;

export const ProgramImg2Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  width: 40%;
  @media only screen and (max-width: 905px) {
    display: none;
  }
`;

export const ProgramImg = styled.img`
  width: 60%;
  @media only screen and (max-width: 905px) {
    margin: 0 auto;
  }
`;

export const ProgramImgMedia = styled.img`
  display: none;
  @media only screen and (max-width: 905px) {
    display: flex;
    width: 30%;
    margin: 24px auto;
    max-width: 179px;
  }
`;

export const ProgramImgMedia2 = styled(ProgramImgMedia)`
  margin-bottom: 0;
`;

// 학습프로세스

export const BeigeSvg = styled.svg`
  display: block;
  @media only screen and (max-width: 905px) {
    padding-bottom: 5%;
  }
`;
export const CloudImg = styled.img`
  width: 150px;
  position: relative;
  top: 105px;
  left: 70%;
  @media only screen and (min-width: 1280px) {
    width: 180px;
    top: 140px;
  }
  @media only screen and (max-width: 505px) {
    display: none;
  }
`;

export const LearningProcess = styled.div`
  @media only screen and (max-width: 830px) {
    padding-bottom: 1%;
  }
`;

export const ProgramTitle2 = styled(ProgramTitle)`
  margin-top: -5%;
`;

export const ProcessStepImg = styled.img`
  width: 60%;
  margin: 0 auto;
  padding-top: 5%;
  display: flex;
  @media only screen and (max-width: 830px) {
    width: 95%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

export const ProcessWrapMedia = styled.img`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 95%;
    margin: 0 auto;
    margin-top: 8%;
  }
`;

export const DabisuRoadmap = styled.div`
  background-color: #f7f3e7;
  padding-bottom: 5%;
  @media only screen and (max-width: 1280px) {
    padding-top: 5%;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 0%;
  }
`;

export const RoadmapProgramTitle = styled(ProgramTitle)`
  margin-top: -100px;
  @media only screen and (max-width: 900px) {
    font-size: 6.8vw;
    margin-top: -5%;
  }
  @media only screen and (max-width: 428px) {
    font-size: 6vw;
    margin-top: -6%;
  }
`;

export const RoadmapDabisu = styled.img`
  width: 200px;
  margin: -20px 20px 0 0;
  @media only screen and (max-width: 428px) {
    width: 35%;
    margin: -15px 10px 0 0;
  }
`;

export const DabisuRoadmapImg = styled.img`
  display: flex;
  margin: 3% auto;
  width: 60%;
  @media only screen and (max-width: 900px) {
    width: 90%;
    margin-top: 8%;
    margin-bottom: 5%;
  }
`;

export const RoadmapStep = styled.div`
  margin: 0 auto;
  border: 1px solid transparent;
  border-radius: 30px;
  background-color: #ffffff;
  width: 65%;
  margin-top: 3%;
  @media only screen and (max-width: 900px) {
    width: 75%;
    margin-top: 5%;
  }
  @media only screen and (max-width: 428px) {
    width: 85%;
  }
`;

export const RoadmapTitle = styled.div`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 1px #ffae02;
  background-color: #ffae02;
  padding: 20px 0;
  color: #ffffff;
  font-family: fontCafe;
  font-size: 35px;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 1280px) {
    font-size: 40px;
    padding: 25px 0;
  }
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 4.2vw;
  }
  @media only screen and (max-width: 428px) {
    font-size: 23px;
  }
`;

export const RoadmapTitle2 = styled(RoadmapTitle)`
  border-bottom: 1px #8ec63f;
  background-color: #8ec63f;
`;

export const RoadmapTitle3 = styled(RoadmapTitle)`
  border-bottom: 1px #22beff;
  background-color: #22beff;
`;

export const RoadmapAge = styled.div`
  color: #ffffff;
  font-family: fontCafe;
  font-size: 23px;
  margin: 1% 0 0 1%;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3vw;
  }
  @media only screen and (max-width: 428px) {
    font-size: 16px;
    margin-top: 1.5%;
  }
`;

export const RoadmapContentWrap = styled.div`
  padding: 5% 8%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  @media only screen and (min-width: 1280px) {
    padding: 4% 8%;
  }
`;

export const ContentPoint = styled.div`
  display: flex;
  justify-content: center;
  font-family: fontCafe;
  font-size: 30px;
  border-bottom: 1px solid #ffae02;
  padding-bottom: 4%;
  @media only screen and (max-width: 900px) {
    font-size: 3.3vw;
  }
  @media only screen and (max-width: 428px) {
    /* font-size: 17px; */
    font-size: 4.2vw;
  }
`;

export const RoadmapContent = styled.div`
  display: flex;
  text-align: center;
  padding: 4% 4% 0 4%;
  font-size: var(--font-size-content0);
  line-height: 1.8;
  font-family: 'fontCafe';

  @media only screen and (max-width: 768px) {
    font-size: var(--font-size-content1);
    padding: 0;
    padding-top: 4%;
  }
  @media only screen and (max-width: 428px) {
    font-size: var(--font-size-content2);
  }
`;

export const SunImage = styled.img`
  width: 100px;
  position: relative;
  top: -230px;
  left: 15%;
  @media only screen and (min-width: 1280px) {
    width: 120px;
    top: -300px;
  }
  @media only screen and (max-width: 820px) {
    width: 80px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    top: -280px;
    left: 20%;
  }
  @media only screen and (max-width: 680px) {
    width: 70px;
    top: -230px;
    left: 60%;
  }
  @media only screen and (max-width: 480px) {
    width: 50px;
    top: -180px;
    left: 80%;
  }
`;

export const StarImage1 = styled.img`
  width: 30px;
  position: relative;
  top: -200px;
  left: 1%;
  @media only screen and (min-width: 1280px) {
    left: 2%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    left: 0%;
    top: -230px;
  }
  @media only screen and (max-width: 428px) {
    width: 20px;
    top: -150px;
  }
`;

export const StarImage2 = styled.img`
  width: 30px;
  position: relative;
  top: -100px;
  left: 76%;
  @media ${({ theme }) => theme.device.tablet} {
    left: 70%;
  }
  @media only screen and (max-width: 428px) {
    width: 20px;
    top: -10px;
    left: 70%;
  }
`;

export const ReadingProgramTitle = styled(ProgramTitle)`
  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 5%;
  }
  @media only screen and (max-width: 428px) {
    font-size: 6vw;
    margin-top: -5%;
    padding-top: 7%;
  }
`;

export const ReadingSubTitle = styled(ProgramSubTitle)`
  //margin-top: -12px;
`;
export const ReadingSlogan = styled.div`
  text-align: center;
  margin: 20px 0;
  @media only screen and (max-width: 428px) {
    margin: 10px 0;
  }
`;
export const ReadingSloganImg = styled.img`
  max-width: 320px;
  @media only screen and (max-width: 428px) {
    max-width: 260px;
  }
`;
export const ReadingAnswerRoadmap = styled.div``;

export const RoadmapReadingAnswer = styled.img`
  width: 200px;
  margin: -20px 20px 0 0;
  @media only screen and (max-width: 428px) {
    width: 35%;
    margin: -15px 10px 0 0;
  }
`;

export const ReadingRoadmapImg = styled.img`
  display: flex;
  margin: 0 auto;
  margin-top: -50px;
  width: 60%;
  @media only screen and (max-width: 820px) {
    display: none;
  }
`;

export const ReadingRoadmapImg2 = styled.img`
  display: none;
  @media only screen and (max-width: 820px) {
    display: flex;
    margin: 0 auto;
    margin-top: -50px;
    width: 90%;
  }
  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const ReadingRoadmapImgMedia = styled.img`
  display: none;
  @media only screen and (max-width: 428px) {
    display: flex;
    margin: 0 auto;
    margin-top: -50px;
    width: 85%;
    margin-top: -10px;
  }
`;

export const TimeLineWrap = styled.div``;

export const ReadingTimeTitle = styled.div`
  position: relative;
`;

export const ReadingAnswerWrap = styled.div`
  padding: 12% 0 9% 0;
  @media only screen and (min-width: 1920px) {
    padding: 15% 0 10% 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 17% 0 15% 0;
  }
`;

export const DescribeReadingAnswer = styled.div`
  font-family: fontCafe;
  color: #ffffff;
  width: 70%;
  text-align: center;
  font-size: 28px;
  display: flex;
  justify-content: center;
  margin: auto;
  line-height: 40px;
  span {
    font-family: fontBold;
  }
  @media only screen and (max-width: 830px) {
    font-size: 20px;
    line-height: 24px;
    width: 80%;
  }
  @media only screen and (max-width: 428px) {
    font-size: 16px;
    line-height: 22px;
    width: 85%;
  }
`;

export const CloudImage = styled.img`
  width: 150px;
  position: relative;
  left: 80%;
  top: 120px;
  @media only screen and (max-width: 900px) {
    width: 120px;
    top: 80px;
  }
`;

export const FinalImage = styled.div`
  background-image: url('images/Kim/MainOrange.png');
  background-repeat: round;
  background-size: cover;
  @media only screen and (max-width: 1200px) {
    background-repeat: no-repeat;
  }
`;

export const FinalWrap = styled.div`
  margin-top: 2%;
  @media only screen and (min-width: 1280px) {
    margin-top: 3%;
  }
`;

export const FinalReadingAnswer = styled.div`
  font-family: fontCafe;
  font-size: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 830px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 428px) {
    font-size: 17px;
  }
`;

export const FinalReadingImg = styled.img`
  width: 22%;
  margin-left: 2%;
  @media only screen and (max-width: 428px) {
    width: 25%;
  }
`;
