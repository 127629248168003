import styled from 'styled-components';

export const ModalWrap = styled.div`
  z-index: 11000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  inset: 0px;
  opacity: 1;
  @media ${({ theme }) => theme.device.tablet} {
    background: rgba(0, 0, 0, 0.4);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @media ${({ theme }) => theme.device.mobile} {
    background: rgba(0, 0, 0, 0.4);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

export const ModalDetail = styled.div`
  /* position: relative; */
  position: fixed;
  bottom: 60px;
  right: 55px;
  overflow-y: auto;
  box-sizing: border-box;
  width: 240px;
  height: 400px;
  border-radius: 25px;
  background: #ffffff;
  overscroll-behavior-y: contain;
  border: 1px solid #ffffff;
  @media ${({ theme }) => theme.device.tablet} {
    position: fixed;
    bottom: 290px;
    right: 260px;
    overflow-y: auto;
    box-sizing: border-box;
    width: 240px;
    height: 400px;
    border-radius: 25px;
    background: #fff;
    overscroll-behavior-y: contain;
    border: 1px solid black;
  }
  @media ${({ theme }) => theme.device.mobile} {
    position: fixed;
    bottom: 220px;
    right: 70px;
    overflow-y: auto;
    box-sizing: border-box;
    width: 240px;
    height: 400px;
    border-radius: 25px;
    background: #fff;
    overscroll-behavior-y: contain;
    border: 1px solid black;
  }
`;

export const ModalCloseBtn = styled.img`
  width: 100%;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const ModalSettingBtn = styled.img``;

export const ChatWrap = styled.div`
  width: 200px;
  height: 300px;
  border: 1px solid;
`;

export const ChatList = styled.div``;

export const ViewReviewWrap = styled.div``;

export const ViewReviewMent = styled.div``;

export const WriteWrap = styled.div``;

export const WriteInput = styled.input`
  width: 150px;
`;

export const SubmitBtn = styled.div``;
