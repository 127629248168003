import React from 'react';
import * as S from './MainKimStyled';
import Program from './Program';
import IntroduceBrand from './IntroduceBrand';

const MainKim = ({ brand, program }) => {
  return (
    <S.Main>
      <IntroduceBrand brand={brand} />
      <Program program={program} />
    </S.Main>
  );
};

export default MainKim;
