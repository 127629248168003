/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { API } from '../../../../config';
import NaverMaps from './NaverMaps.js';
import * as S from './ShopFindStyled.js';
import Pagenation from './Pagenation';
import Fade from 'react-reveal/Fade';

const ShopFind = ({ findFranchisee }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  //const [cate, setCate] = useState(['39', '40', '41', '42', '8939', '113']);
  const [cate, setCate] = useState(['39, 42']);
  const [keyword, setKeyword] = useState('');
  const [bounds, setBounds] = useState('');
  const [userLocation, setUserLocation] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(5);
  const [mapData, setMapData] = useState([]);
  const [clickedItem, setClickedItem] = useState(undefined);
  const [inputValue, setInputValue] = useState([
    '지사',
    '원',
    '교습소',
    '공부방',
    '방문센터',
    '기타',
  ]);
  const [checkedValues, setCheckedValues] = useState([
    '지사',
    '원',
    '교습소',
    '공부방',
    '방문센터',
    '기타',
  ]);

  const cateString = cate.join(',');

  function handleClick(e) {
    e.stopPropagation();
    setView(!view);
  }

  const handleCheckboxChange = e => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    const code = e.target.dataset.code;
    if (isChecked) {
      setCheckedValues([...checkedValues, value]);
      setInputValue([...inputValue, value]);
      setCate([...cate, code]);
    } else {
      setCheckedValues(checkedValues.filter(item => item !== value));
      setInputValue(inputValue.filter(item => item !== value));
      setCate(cate.filter(item => item !== code));
    }
  };

  useEffect(() => {
    fetch(`${API.list}`)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
      });
  }, []);

  const fetchListData = () => {
    fetch(`${API.list}?keyword=${keyword}&page=1&cate=${cate}`)
      .then(res => res.json())
      .then(data => {
        setData(data);
        setCurrentPage(1);
        setView(false);
      });
  };

  const fetchMarkerData = () => {
    fetch(`${API.marker}?keyword=${keyword}&cate=${cate}`)
      .then(res => res.json())
      .then(data => {
        setMapData(data.result);
      });
  };

  const pressEnter = e => {
    if (e.keyCode === 13) {
      fetchListData();
      fetchMarkerData();
    }
  };

  const clickListItem = (index) => {
    //setClickedItem(data.result[index]);
    setClickedItem(index);
  }

  if (loading) return <div>loading</div>;
  return (
    <S.StudyRoomWrap ref={findFranchisee}>
      <Fade bottom>
        <S.TextCenter>
          <S.Category>지사 찾기</S.Category>
          <S.BoldTitle>전국 지사 안내</S.BoldTitle>
        </S.TextCenter>
      </Fade>
      <S.StudyRoomSearch>
        {/** 
        <S.Ul onClick={handleClick}>
          <S.ToggleWrap>
            {inputValue.length > 0
              ? inputValue.map((item, index) => (
                  <span key={item}>
                    {item}
                    {index !== inputValue.length - 1 && ', '}
                  </span>
                ))
              : '검색유형 중복 선택 가능'}
            {view ? <S.Toggle>▲</S.Toggle> : <S.Toggle>▼</S.Toggle>}
          </S.ToggleWrap>
          {view && (
            <S.DropBorder>
              <S.Span bold>검색 유형 </S.Span>
              <S.Span small>중복 선택 가능</S.Span>
              {DROPBOX_LIST.map(({ title, id, code }) => (
                <S.DropList key={id} onClick={e => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    value={title}
                    onChange={handleCheckboxChange}
                    checked={checkedValues.includes(title)}
                    data-code={code}
                  />
                  <span>{title}</span>
                </S.DropList>
              ))}
            </S.DropBorder>
          )}
        </S.Ul>
        */}
        <S.SearchWrap>
          <S.SearchText
            placeholder="지역 또는 지사명을 입력하세요."
            onChange={e => setKeyword(e.target.value)}
            value={keyword}
            onKeyUp={pressEnter}
          />
          <S.SearchBtn
            onClick={() => {
              fetchListData();
              fetchMarkerData();
            }}
          >
            검색
          </S.SearchBtn>
        </S.SearchWrap>
      </S.StudyRoomSearch>
      <S.SearchMobile>
        <S.SearchWrapMobile>
          <S.SearchText
            placeholder="지역 또는 지사명을 입력하세요."
            onChange={e => setKeyword(e.target.value)}
            value={keyword}
            onKeyUp={pressEnter}
          />
          <S.SearchBtn
            onClick={() => {
              fetchListData();
              fetchMarkerData();
            }}
          >
            검색
          </S.SearchBtn>
        </S.SearchWrapMobile>
        {/**
        <S.UlMobile onClick={handleClick}>
          <S.ToggleWrap>
            {inputValue.length > 0
              ? inputValue.map((item, index) => (
                  <span key={item}>
                    {item}
                    {index !== inputValue.length - 1 && ', '}
                  </span>
                ))
              : '검색유형 중복 선택 가능'}
            {view ? <S.Toggle>▲</S.Toggle> : <S.Toggle>▼</S.Toggle>}
          </S.ToggleWrap>
          {view && (
            <S.DropBorder>
              <S.Span bold>검색 유형 </S.Span>
              <S.Span small>중복 선택 가능</S.Span>
              {DROPBOX_LIST.map(({ title, id, code }) => (
                <S.DropList key={id} onClick={e => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    value={title}
                    onChange={handleCheckboxChange}
                    checked={checkedValues.includes(title)}
                    data-code={code}
                  />
                  <span>{title}</span>
                </S.DropList>
              ))}
            </S.DropBorder>
          )}
        </S.UlMobile>
        */}
      </S.SearchMobile>
      <S.StudyRoomDetail>
        <S.RoomMapWrap>
          <NaverMaps
            data={data}
            setData={setData}
            cate={cate}
            keyword={keyword}
            setKeyword={setKeyword}
            bounds={bounds}
            setBounds={setBounds}
            userLocation={userLocation}
            setUserLocation={setUserLocation}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            fetchListData={fetchListData}
            mapData={mapData}
            setMapData={setMapData}
            clickedItem={clickedItem}
            setClickedItem={setClickedItem}
          />
        </S.RoomMapWrap>

        {data.count === 0 ? (
          <S.FranchiseEmptyList>
            <S.TheNumWrap>
              <S.TheNumFranchise>검색 결과, {data.count}개</S.TheNumFranchise>
            </S.TheNumWrap>
            <S.FranchiseEmptyWrap>
              <S.CountCommentWrap>
                <S.CountComments>
                  <S.CountComment bold size>
                    검색 결과가 없습니다.
                  </S.CountComment>
                  검색어를 확인해주세요.
                </S.CountComments>
              </S.CountCommentWrap>
            </S.FranchiseEmptyWrap>
          </S.FranchiseEmptyList>
        ) : (
          <S.FranchiseList>
            <S.TheNumWrap>
              <S.TheNumFranchise>검색 결과, {data.count}개</S.TheNumFranchise>
            </S.TheNumWrap>
            <S.FranchiseListWrap>
              {data.result !== undefined
                ? data.result.map(
                    ({ id, name, addr, parenthp }, index) => {
                      return (
                        <S.FranchiseBox key={id} clicked={(clickedItem != undefined) && clickedItem.id == id ? true : false}>
                          <S.FranchiseMouse onClick={() => clickListItem(index)}>
                            <S.FranchiseTitle>{name}</S.FranchiseTitle>
                            <S.FranchiseIn>{addr}</S.FranchiseIn>
                            {/** 
                            <S.FranchiseeTelWrap>
                              <S.FranchiseeTel>T. &nbsp;{parenthp}</S.FranchiseeTel>
                            </S.FranchiseeTelWrap>
                            */}
                          </S.FranchiseMouse>
                        </S.FranchiseBox>
                      );
                    }
                  )
                : ''}
            </S.FranchiseListWrap>
            <Pagenation
              data={data}
              setData={setData}
              keyword={keyword}
              cate={cate}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </S.FranchiseList>
        )}
      </S.StudyRoomDetail>
    </S.StudyRoomWrap>
  );
};
export default ShopFind;

const DROPBOX_LIST = [
  { id: 181, title: '지사', code: 39 },
  { id: 182, title: '원', code: 40 },
  { id: 183, title: '교습소', code: 41 },
  { id: 184, title: '공부방', code: 42 },
  { id: 185, title: '방문센터', code: 8939 },
  { id: 10006, title: '기타', code: 113 },
];
