/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { API } from '../../config';

export default function SchedulePopup() {
  const [open, setOpen] = React.useState(false);
  const [scheduleData, setScheduleData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }; 
  useEffect(() => {
		var preURL = document.referrer;
		if(preURL == 'http://dabisu6868.cafe24.com' || preURL == 'http://dabisu6868.cafe24.com/default/'){
      fetch(`${API.schedule}`)
      .then(res => res.json())
      .then(data => {
        setScheduleData(data);
      });
		}
  }, []);

  useEffect(() => {
    if(scheduleData.count > 0){
      handleOpen();
    }
  }, [scheduleData]);

  return (
    <div>
      <DialogContainer
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Title id="customized-dialog-title" onClose={handleClose}>
          	새다론 사업설명회 일정
        </Title>
        <DialogContent>
            { scheduleData.result &&
              scheduleData.result.map(({ id, title, date }) => {
				return (
          <React.Fragment key={id}>
            <Typography gutterBottom>
              {title}
              <br />
              <span style={{ color: '#333333' }}>
                {new Intl.DateTimeFormat('default', {
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                }).format(new Date(date))}
              </span>
            </Typography>
            <br />    
          </React.Fragment>
				);
            })}
        </DialogContent>
      </DialogContainer>
    </div>
  );
}

const DialogContainer = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		minWidth: "40%",
		[theme.breakpoints.down('lg')]: {
			minWidth: "60%",
		},
		[theme.breakpoints.down('md')]: {
			minWidth: "80%",
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: "90%",
		}
	},
	'& .MuiTypography-root': {
		fontSize: "22px",
		color: "darkblue",
		fontFamily: "fontCafe",
    textAlign: "center",
	},
	'& .MuiDialogTitle-root': {
		fontSize: "30px",
		color: "#B95A82",
		fontFamily: "fontCafe",
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function Title(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 1, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};