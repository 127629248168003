import styled from 'styled-components';

export const Width = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;
