import React from 'react';
import * as S from './ProgramStyled';
import { Width } from '../../../styles/common';
import Fade from 'react-reveal/Fade';

const Program = ({ program }) => {
  return (
    <S.Program ref={program}>
      {/* 학습프로그램 */}
      <S.ProgramImage>
        <Width>
          <S.LearningProgram>
            <Fade bottom>
              <S.ProgramTitle>Program</S.ProgramTitle>
              <S.ProgramSubTitle>새다론 학습 프로그램</S.ProgramSubTitle>
            </Fade>
            <Fade right>
              <S.ProgramContent>
                <S.ProgramWrap>
                  <S.ExplainWrap>
                    <S.ProgramName src="images/Kim/Dabisu.png" alt="다비수" />
                    <S.ProgramExplain>
                      1단계 - 연산 초급
                      <br />
                      2단계 - 연산 중급
                      <br />
                      3단계 - 연산 고급
                      <br />
                      4단계 - 영역별 연계 응용 학습
                    </S.ProgramExplain>
                  </S.ExplainWrap>
                </S.ProgramWrap>
                <S.ProgramImgWrap>
                  <S.ProgramImg
                    src="images/Kim/ProgramImg.png"
                    alt="캐릭터 사진"
                  />
                </S.ProgramImgWrap>
              </S.ProgramContent>
              <S.ProgramImgMedia
                src="images/Kim/ProgramImg.png"
                alt="캐릭터 사진"
              />
            </Fade>
            <Fade left>
              <S.ProgramContent>
                <S.ProgramWrap>
                  <S.ExplainWrap>
                    <S.ProgramName
                      src="images/Kim/Reading.png"
                      alt="리딩앤써"
                    />
                    <S.ProgramExplain>
                      1단계 - 읽기 방법 지도
                      <br />
                      2단계 - 생각의 구조화
                      <br />
                      3단계 - 1, 2단계 응용, 교과 필독서 읽고 정리
                      <br />
                    </S.ProgramExplain>
                  </S.ExplainWrap>
                </S.ProgramWrap>
                <S.ProgramImg2Wrap>
                  <S.ProgramImg
                    src="images/Kim/ProgramImg2.png"
                    alt="캐릭터 사진"
                  />
                </S.ProgramImg2Wrap>
              </S.ProgramContent>
              <S.ProgramImgMedia2
                src="images/Kim/ProgramImg2.png"
                alt="캐릭터 사진"
              />
            </Fade>
          </S.LearningProgram>
        </Width>
      </S.ProgramImage>
      {/* 학습프로세스 */}
      <S.BeigeSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#f7f3e6"
          fillOpacity="1"
          d="M0,64L60,58.7C120,53,240,43,360,69.3C480,96,600,160,720,170.7C840,181,960,139,1080,122.7C1200,107,1320,117,1380,122.7L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        />
      </S.BeigeSvg>
      <Fade bottom>
        <S.LearningProcess>
          <S.ProgramTitle2>학습 프로세스</S.ProgramTitle2>
          <S.ProgramSubTitle>
            새다른 공부방의 체계적인 맞춤형 프로세스
          </S.ProgramSubTitle>
          <S.ProcessStepImg
            src="images/Kim/Step.png"
            alt="학습 프로세스 과정"
          />
          <S.ProcessWrapMedia
            src="images/Kim/StepMedia.png"
            alt="학습 프로세스 과정"
          />
          <S.CloudImg src="images/Kim/Cloud.png" alt="구름" />
        </S.LearningProcess>
      </Fade>
      {/* 다비수 학습로드맵 */}
      <S.BeigeSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#f7f3e6"
          fillOpacity="1"
          d="M0,96L60,90.7C120,85,240,75,360,80C480,85,600,107,720,112C840,117,960,107,1080,90.7C1200,75,1320,53,1380,42.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        />
      </S.BeigeSvg>
      <S.DabisuRoadmap>
        <Fade left>
          <S.RoadmapProgramTitle>
            <S.RoadmapDabisu src="images/Kim/Dabisu.png" alt="다비수" />
            학습 로드맵
          </S.RoadmapProgramTitle>
          <S.ProgramSubTitle>상세 커리큘럼</S.ProgramSubTitle>
        </Fade>
        <Fade left>
          <S.RoadmapStep>
            <S.RoadmapTitle>
              기초탄탄<S.RoadmapAge>(7세~초등 2학년)</S.RoadmapAge>
            </S.RoadmapTitle>
            <S.RoadmapContentWrap>
              <S.ContentPoint>
                큰 수 덧셈, 뺄셈, 곱셈, 나눗셈, 분수
              </S.ContentPoint>
              <S.RoadmapContent>
                다비수 5종 원리셈을 통해 사칙연산, 분수까지의 과정을 쉽고
                재미있게 익혀 실전응용에 도전할 수 있도록 수학적 뇌구조와
                사고영역을 확장시켜 줍니다.
              </S.RoadmapContent>
            </S.RoadmapContentWrap>
          </S.RoadmapStep>
        </Fade>
        <Fade left>
          <S.RoadmapStep>
            <S.RoadmapTitle2>
              실전응용<S.RoadmapAge>(초등 2~4학년)</S.RoadmapAge>
            </S.RoadmapTitle2>
            <S.RoadmapContentWrap>
              <S.ContentPoint>곱셈, 나눗셈, 분수, 제곱수</S.ContentPoint>
              <S.RoadmapContent>
                빅토리 과정을 통해 수 개념이 확장되어 큰 수 사칙연산도 쉽게
                해결하며 다비수의 결합과 분해 원리로 곱셈, 나눗셈, 제곱수를 더욱
                쉽게 학습할 수 있습니다.
              </S.RoadmapContent>
            </S.RoadmapContentWrap>
          </S.RoadmapStep>
        </Fade>
        <Fade left>
          <S.RoadmapStep>
            <S.RoadmapTitle3>
              완전정복<S.RoadmapAge>(초등 4~6학년)</S.RoadmapAge>
            </S.RoadmapTitle3>
            <S.RoadmapContentWrap>
              <S.ContentPoint>분수, 소수, 비례, 혼합계산, 수열</S.ContentPoint>
              <S.RoadmapContent>
                분수, 소수(초등범위), 양수, 음수(중등범위) 사칙을 해결하는
                융복합적 수리력이 길러지며 수를 바라보는 직관력, 판단력,
                응용력이 향상되어 응용수학을 해결할 수 있게 됩니다.
              </S.RoadmapContent>
            </S.RoadmapContentWrap>
          </S.RoadmapStep>
        </Fade>
      </S.DabisuRoadmap>
      {/* 리딩앤써 학습 로드맵 */}
      <S.BeigeSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#f7f3e6"
          fillOpacity="1"
          d="M0,224L60,208C120,192,240,160,360,144C480,128,600,128,720,144C840,160,960,192,1080,202.7C1200,213,1320,203,1380,197.3L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        />
      </S.BeigeSvg>
      <Fade bottom>
        <S.ReadingProgramTitle>
          <S.RoadmapReadingAnswer src="images/Kim/Reading.png" alt="리딩앤써" />
          학습 로드맵
        </S.ReadingProgramTitle>
        <S.ReadingSubTitle>상세 커리큘럼</S.ReadingSubTitle>
        <S.ReadingSlogan>
          <S.ReadingSloganImg src="images/Kim/ReadingSlogan.png" alt="slogan" />
        </S.ReadingSlogan>
        {/* <S.SunImage src="images/Kim/Sun.png" alt="Design" /> */}
        <S.StarImage1 src="images/Kim/Star2.png" alt="Design" />
        <S.StarImage2 src="images/Kim/Star2.png" alt="Design" />
      </Fade>
      <S.ReadingAnswerRoadmap>
        <Fade bottom>
          <S.ReadingRoadmapImg
            src="images/Kim/ReadingRoadmap.png"
            alt="리딩앤써 로드맵"
          />
          <S.ReadingRoadmapImg2
            src="images/Kim/ReadingRoadmap.png"
            alt="리딩앤써 로드맵 (태블릿 확인용)"
          />
          <S.ReadingRoadmapImgMedia
            src="images/Kim/TimeLineMobile.png"
            alt="리딩앤써 로드맵"
          />
        </Fade>
        <S.FinalImage>
          <Width>
            <Fade>
              <S.ReadingAnswerWrap>
                <S.DescribeReadingAnswer>
                  <p>
                    공부의 기초 체력인 읽기 능력부터 서술형 평가 시대에 대비하기
                    위한 글쓰기 능력까지 완전 학습
                  </p>
                </S.DescribeReadingAnswer>
                <S.FinalWrap>
                  <S.FinalReadingAnswer>
                    언어 글쓰기 논술 완성은
                    <S.FinalReadingImg
                      src="images/Kim/ReadingWhite.png"
                      alt="리딩앤써"
                    />
                  </S.FinalReadingAnswer>
                </S.FinalWrap>
              </S.ReadingAnswerWrap>
            </Fade>
          </Width>
        </S.FinalImage>
      </S.ReadingAnswerRoadmap>
    </S.Program>
  );
};

export default Program;
