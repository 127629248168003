/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import { API } from '../../../config';

export default function MailPopup({open, setOpen}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [from, setFrom] = useState('');
  const [phone, setPhone] = useState('');
  const [path, setPath] = useState('');
  const [location, setLocation] = useState('');
  const [message, setMessage] = useState('');
  const [isCheck, setIsCheck] = useState(false);

  const popClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popopen = Boolean(anchorEl);
  const popid = popopen ? 'simple-popover' : undefined;

  const handleClose = () => {
    setOpen(false);
    setFrom('');
    setPhone('');
    setPath('');
    setLocation('');
    setMessage('');
  };
  const sendClick = (event) => {
    event.preventDefault();
    if(isCheck){
      fetch(`${API.sendmail}`, {
        method: "POST",
        body: JSON.stringify({
          from: from,
          phone: phone,
          path: path,
          location: location,
          message: message
        }),
      })
      .then(response => {
        handleClose();
        alert("상담신청이 완료되었습니다.");
      })
      .catch(error => {
        alert("죄송합니다. 다시 시도해주세요.");
      })
    }else{
      alert("개인정보 수집에 동의해주세요.");
    }
  };

  return (
    <div>
      <DialogContainer
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Title id="customized-dialog-title" onClose={handleClose}>
          <span style={{fontSize: "28px"}}>새다론 공부방</span>
          <br/>
          <span style={{color: "#25ade3"}}>창업 상담</span> 신청
        </Title>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={3} sm={2} sx={{ alignSelf: 'center', textAlign: 'end' }}>
              <p style={{ fontFamily: 'fontCafe', size: '28px' }}>작성자</p>
            </Grid>
            <Grid item xs={9} sm={10}>
              <TextField id="outlined-basic" label="작성자" variant="outlined" sx={{ width: '90%', margin: 1 }} value={from} onChange={(e) => setFrom(e.target.value)}/>
            </Grid>
            <Grid item xs={3} sm={2} sx={{ alignSelf: 'center', textAlign: 'end' }}>
              <p style={{ fontFamily: 'fontCafe', size: '28px' }}>연락처</p>
            </Grid>
            <Grid item xs={9} sm={10}>
              <TextField id="outlined-basic" label="연락처" variant="outlined" sx={{ width: '90%', margin: 1 }} value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </Grid>
            <Grid item xs={3} sm={2} sx={{ alignSelf: 'center', textAlign: 'end' }}>
              <p style={{ fontFamily: 'fontCafe', size: '28px' }}>유입경로</p>
            </Grid>
            <Grid item xs={9} sm={10}>
              <TextField id="outlined-basic" label="유입경로" variant="outlined" sx={{ width: '90%', margin: 1 }} value={path} onChange={(e) => setPath(e.target.value)}/>
            </Grid>
            <Grid item xs={3} sm={2} sx={{ alignSelf: 'center', textAlign: 'end' }}>
              <p style={{ fontFamily: 'fontCafe', size: '28px' }}>희망지역</p>
            </Grid>
            <Grid item xs={9} sm={10}>
              <TextField id="outlined-basic" label="희망지역" variant="outlined" sx={{ width: '90%', margin: 1 }} value={location} onChange={(e) => setLocation(e.target.value)}/>
            </Grid>
            <Grid item xs={3} sm={2} sx={{ alignSelf: 'center', textAlign: 'end' }}>
              <p style={{ fontFamily: 'fontCafe', size: '28px' }}>문의내용</p>
            </Grid>
            <Grid item xs={9} sm={10}>
              <TextField id="outlined-basic" label="문의내용" variant="outlined" multiline minRows={4} sx={{ width: '90%', margin: 1 }} value={message} onChange={(e) => setMessage(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sx={{ alignSelf: 'center', textAlign: 'center', fontFamily: 'fontCafe', verticalAlgin: 'middle' }}>
              <Checkbox required checked={isCheck} onChange={(e) => setIsCheck(e.target.checked)} />개인정보 수집 및 이용 동의
              <Button aria-describedby={popid} type="button" onClick={popClick} sx={{ fontFamily: 'fontCafe' }}>[내용보기]</Button>
              <Popper id={popid} open={popopen} anchorEl={anchorEl} sx={{ zIndex: '1500', backgroundColor: '#F0F0F0',borderRadius: '8px', boxShadow: '1px 1px 1px 1px lightgray' }}>
                <Typography sx={{ fontFamily: 'fontCafe', fontSize: '14px', p: 2 }}>
                  * 개인정보의 수집 및 이용목적<br />
                  - 서비스 이용에 따른 본인식별, 실명확인<br />
                  - 고지사항 전달, 불만처리 의사소통 경로 확보<br />
                  - 신규 서비스 등 최신정보 안내 및 개인맞춤서비스 제공을 위한 자료<br />
                  - 기타 원활한 양질의 서비스 제공 등<br /><br />
                  * 수집하는 개인정보의 항목<br />
                  - 이름, 핸드폰번호, 그 외 선택항목<br /><br />
                  * 개인정보의 보유 및 이용기간<br />
                  - 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시<br />지체 없이 파기합니다.<br />
                  - 다만, 원활한 서비스의 상담을 위해 상담 완료 후<br />내용을 3개월간 보유할 수 있으며<br />전자상거래에서의 소비자보호에 관한 법률 등 타법률에 의해<br />보존할 필요가 있는 경우에는 일정기간 보존합니다. 
                </Typography>
              </Popper>
            </Grid>
            <Grid item xs={12} sx={{ alignSelf: 'center', textAlign: 'center' }}>
              <Button type="submit" variant="contained" size="large" endIcon={<SendIcon />} onClick={sendClick} sx={{ margin: '10px 0', backgroundColor: '#25ade3', fontFamily: 'fontCafe', borderRadius: '20px' }}>신청하기</Button> 
            </Grid>
          </Grid>
        </DialogContent>
      </DialogContainer>
    </div>
  );
}

const DialogContainer = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
    border: "10px #25ade3 solid",
		minWidth: "40%",
		[theme.breakpoints.down('lg')]: {
			minWidth: "60%",
		},
		[theme.breakpoints.down('md')]: {
			minWidth: "80%",
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: "90%",
		}
	},
	'& .MuiTypography-root': {
		fontSize: "22px",
		fontFamily: "fontCafe",
    textAlign: "center",
	},
	'& .MuiDialogTitle-root': {
		fontSize: "40px",
    color: "black",
		fontFamily: "fontCafe",
    lineHeight: "45px",
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function Title(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 1, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};