/* eslint-disable */
import React, { useCallback } from 'react';
import axios from 'axios';
import * as S from './IntroduceBrandStyled';
import { Width } from '../../../styles/common';
import Fade from 'react-reveal/Fade';

const IntroduceBrand = ({ brand }) => {
  const exportAI = useCallback(() => {
    let fileName = 'Logo.ai';
    const url = 'images/Kim/DabisuLogo.ai';
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const objectUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }, []);

  const exportPNG = useCallback(() => {
    let fileName = 'Logo.png';
    const imgUrl = 'images/Kim/DabisuLogo.png';
    axios
      .get(imgUrl, {
        responseType: 'blob',
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'image/png' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <S.IntroduceBrand>
      <S.IntroImage>
        <Width>
          <S.IntroWrap>
            <S.IntroTitleBox>
              <S.IntroTitleImg src="images/Kim/Subtitle.png" />
              <S.IntroSubtitleWrap>
                <S.IntroSubtitleYellow>다비수</S.IntroSubtitleYellow>로
                <S.IntroSubtitleYellow> 시작</S.IntroSubtitleYellow>해
                <S.IntroSubtitleBlue> 논술</S.IntroSubtitleBlue>로
                <S.IntroSubtitleBlue> 완성</S.IntroSubtitleBlue>합니다.
              </S.IntroSubtitleWrap>
            </S.IntroTitleBox>
            <S.IntroImgWrap>
              <S.MainSpace src="images/Kim/DabisuSpace1.png" />
              <S.IntroImg src="images/Kim/IntroChild.png" />
              <S.MainSpace2 src="images/Kim/DabisuSpace2.png" />
            </S.IntroImgWrap>
          </S.IntroWrap>
        </Width>
      </S.IntroImage>
      <S.PlusImage>
        <Width>
          <Fade bottom>
            <S.MixContents src="images/Kim/IntroPlus.png" />
            <S.IntroLogoWrap>
              <S.MixLogo src="images/Kim/IntroLogo.png" />
              <S.MixContent src="images/Kim/IntroSlogan.png" />
            </S.IntroLogoWrap>
            <S.DescriptionWrap>
              <S.Description>
                다비수와 리딩앤써 콘텐츠 커리큘럼 기반의&nbsp;
                <S.DescriptionBold>
                  새로운 통합 학습 공부방.&nbsp;
                </S.DescriptionBold>
                체계적인 수학 기초 원리 연산 학습으로&nbsp;
                <S.DescriptionBold>
                  초등수학의 뿌리부터 열매까지&nbsp;
                </S.DescriptionBold>
                탄탄하게 만들어줍니다.
              </S.Description>
            </S.DescriptionWrap>
          </Fade>
        </Width>
      </S.PlusImage>
      <S.BrandImage>
        <Width>
          <S.IntroduceBIWrap ref={brand}>
            <Fade left>
              <S.ExplainBrand>
                <S.BILogoWrap>
                  <S.BILogo src="images/Kim/LogoDabisu.png" alt="BI_Logo" />
                </S.BILogoWrap>
                <S.LogoContent>
                  <S.BITitleWrap>
                    <S.BITitle src="images/Kim/BITitle.png" alt="BI_Title" />
                    <S.BISubTitle>새다론 BI 소개</S.BISubTitle>
                  </S.BITitleWrap>
                  <S.ExplainBI>
                    새다론의 초성(ㅅ,ㄷ,ㄹ)을 조합해서&nbsp;
                    <S.DescriptionBold>
                      집이 연상되는 직관적인 이미지
                    </S.DescriptionBold>
                    를 만들고자 하였습니다. 다비수를 상징하는 브랜드 컬러인
                    <S.BlueColor> Blue</S.BlueColor>,
                    <S.YellowColor> Yellow</S.YellowColor>,
                    <S.PinkColor> Pink</S.PinkColor>를
                    사용하였고, 그 중 가장 안정적으로 중심을 잡아줄 수 있는
                    Blue를 메인 컬러로 지정하여 다비수를 표현
                    하였습니다.
                  </S.ExplainBI>
                  <S.DownloadWrap>
                    <S.AIDownload onClick={() => exportAI()}>
                      AI 다운로드
                      <S.DownloadImg src="images/Kim/download.png" />
                    </S.AIDownload>
                    <S.PNGDownload onClick={() => exportPNG()}>
                      PNG 다운로드
                      <S.DownloadImg src="images/Kim/download.png" />
                    </S.PNGDownload>
                  </S.DownloadWrap>
                </S.LogoContent>
              </S.ExplainBrand>
            </Fade>
          </S.IntroduceBIWrap>
        </Width>
      </S.BrandImage>
    </S.IntroduceBrand>
  );
};

export default IntroduceBrand;
