import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import FontBold from '../Font/NEXON_Lv1_Gothic_OTF_Bold.otf';
import FontLight from '../Font/NEXON_Lv1_Gothic_OTF_Light.otf';
import FontBase from '../Font/NEXON_Lv1_Gothic_OTF.otf';
import Cafe24 from '../Font/Cafe24Ssurround.ttf';

const GlobalStyle = createGlobalStyle`
${reset}  
:root {
  --font-size-title1: 50px;
  --font-size-title2: 32px;
  --font-size-content0: 24px;
  --font-size-content1: 20px;
  --font-size-content2: 18px;
  --font-size-content3: 14px;
  --font-size-content4: 11px;
}
@font-face {
  font-family: 'FontBase';
  src: url(${FontBase})
} 
@font-face {
  font-family: 'FontBold';
  src: url(${FontBold})
} 
@font-face {
  font-family: 'FontLight';
  src: url(${FontLight})
} 
@font-face {
  font-family: 'fontCafe';
  src: url(${Cafe24})
} 

body {
  font-family: FontBase;
  word-break: keep-all;
}
`;

export default GlobalStyle;
