import styled from 'styled-components';

export const Nav = styled.div`
  position: relative;
`;

export const NavBox = styled.div`
  position: fixed;
  padding: 0 2% 0 5%;
  box-sizing: border-box;
  width: 102%;
  z-index: 999;
`;

export const NavScroll = styled(NavBox)`
  background-color: #ffffff;
  opacity: 1;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
`;

export const NavWrap = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  @media only screen and (max-width: 530px) {
    height: 70px;
  }
  @media only screen and (max-width: 310px) {
    height: 50px;
  }
`;

export const NavLogo = styled.img`
  cursor: pointer;
  height: 40px;
  @media only screen and (max-width: 530px) {
    height: 36px;
  }
  @media only screen and (max-width: 330px) {
    height: 30px;
  }
`;

export const NavContainer = styled.div`
  li {
    display: inline-block;
    margin: 0 15px;
    font-size: 20px;
  }
  img {
    margin: 0 15px;
  }
  @media only screen and (max-width: 1150px) {
    display: none;
    position: absolute;
  }
`;

export const NavLi = styled.li`
  color: #ffffff;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: 'fontCafe';
  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export const NavLiWhite = styled(NavLi)`
  color: black;
`;
export const NavNum = styled.img`
  width: 19%;
  vertical-align: bottom;
`;

export const Menubar = styled.img`
  display: none;
  @media only screen and (max-width: 1150px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-right: 20px;
    cursor: pointer;
  }
  @media only screen and (max-width: 530px) {
    height: 18px;
    width: 18px;
  }
`;

export const NavToggle = styled.div`
  display: none;
  @media only screen and (max-width: 1150px) {
    display: block;
    position: absolute;
    right: 0;
    border: 1px solid transparent;
    width: 100%;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #000033;
    z-index: 1000;
    box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
      0 7px 10px -5px rgba(0, 0, 0, 0.15);
  }
  @media only screen and (max-width: 850px) {
    padding: 1.5% 0;
  }
`;

export const NavToggleWrap = styled.div`
  li {
    list-style: none;
    line-height: 50px;
    font-size: 22px;
    font-family: 'fontCafe';
    color: #e5e5e3;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 850px) {
    li {
      line-height: 42px;
    }
  }
`;
